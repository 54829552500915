.BuilditInput {
  display: inline-flex;
  flex-flow: column;
  width: 100%;
  position: relative;
  height: fit-content;
  // SearchPlace 열려진상태

  &.default {
    color: #cccccc;
  }

  &.table {
    background-color: #161A24 !important;
    height: 50px !important;
  }
  &.textarea {
    >.input-wrapper {
      display: block;
    }
  }

  &.open {
    >.input-wrapper {
      border-bottom: 1px solid #ccc !important;
    }
  }

  &.error {
    >.input-wrapper {
      border-bottom: 1px solid #E81C1C !important;
      &.focus, &:focus, &:hover, &.opened {
        border-bottom: 1px solid #E81C1C !important;
      }
    }
  }
  &.select {
    * {
      cursor: pointer;
    }
    >.input-wrapper {
      background-color: #0C111B;
      >.select-icon {
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 100%;
        padding-right: 5px;
        box-sizing: border-box;
        >.icon {
          width: 20px;
          height: 20px;
        }
      }
    }

    &.unit {
      >.input-wrapper {
        background-color: #161A24;
        box-sizing: border-box;
        width: 80px;
        border-bottom: 1px solid #161A24;
        min-height: 20px;
        >input {
          padding: 0px 0px 0px 10px;
          font-size: 12px;
          height: inherit;
        }

        &.hover-disable {
          &.focus, &:focus, &:hover, &.opened {
            border-bottom: 0px solid #161A24 !important;
          }
        }

        &.focus, &:focus, &:hover, &.opened {
          border-bottom: 1px solid #161A24 !important;
        }
      }
      >.select-wrapper {      
        border: 1px solid #555555;
        margin-top: 20px;
      }
    }
  }
  
  >.select-wrapper {
    display: flex;
    flex-flow: column;
    background-color: #0C111B;
    position: absolute;
    margin-top: 40px;
    z-index: 1;
    border: 1px solid #555555;
    border-top: none;
    box-sizing: border-box;
    width: inherit;
    
    max-height: 300px;
    overflow: auto;

    &.select-table {
      margin-top: 50px !important;
    }

    >.option {
      display: flex;
      box-sizing: border-box;
      padding: 10px;
      align-items: center;
      height: 40px;
      color: #ccc;
      background-color: #0C111B;
      font-size: 14px;
      cursor: pointer;
      &.option-table {
        padding: 0px !important;
        justify-content: center !important;
      }
      &:first-child {
        border-top: 0px;
      }
      
      &:hover {
        background-color: #273945;
      }
    }
  }

  >.input-wrapper {
    background-color: #0C111B;
    box-sizing: border-box;
    width: inherit;
    color: inherit;
    position: relative;
    border-bottom: 1px solid #0c111b;
    display: inline-flex;
    flex-flow: row;
    align-items: center;
    min-height: 40px;
    height: inherit;
    >.custom-placeholder {
      width: 100%; 
      height: 100%; 
      padding-Left: 10px; 
      align-Items: center;
      display: flex
    }
    >.start-adornment {
      display: flex;
      align-items: center;
      height: inherit;
      padding-left: 10px;
      box-sizing: border-box;
    }
    >.end-adornment {
      display: flex;
      align-items: center;
      height: inherit;
      padding-right: 10px;
      box-sizing: border-box;
    }

    &.hover-disable {
      &.focus, &:focus, &:hover, &.opened {
        border-bottom: 0px solid #161A24 !important;
      }
    }
  
    &.focus, &:focus, &:hover, &.opened {
      border-bottom: 1px solid #ccc;
      transition: border-bottom-color 0.5s linear;
      background-color: #0C111B;
    }

    >textarea {
      font-family: 'Roboto', 'Noto Sans KR';
      min-height: 39px;
      box-sizing: border-box;
      width: 100%;
      border: 0px;
      color: inherit;
      padding: 10px;
      font-size: 14px;
      resize: vertical;
      background-color: inherit;
      line-height: 150%;

      &:focus, &:hover {
        outline: none;
      }
    }
  
    >input {
      font-family: 'Roboto', 'Noto Sans KR';
      box-sizing: border-box;
      border: 0px;
      width: 100%;
      background-color: inherit;
      height: calc(100% - 1px);
      min-height: 39px;
      color: inherit;
      padding: 10px;
      font-size: 16px;
      min-width: 10px;
      &:focus, &:hover {
        outline: none;
      }

      &::placeholder {
        font-size: 14px;
      }
      &.text, &.password {
        font-size: 14px; 
      }
      &.number {
        text-align: right;
      }
      &.right {
        text-align: right;
      }
      &.left {
        text-align: left;
      }
      &.center {
        text-align: center;
      }
    }
  
  
    input[type=number]::-webkit-inner-spin-button, 
    input[type=number]::-webkit-outer-spin-button { 
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        margin: 0; 
    }
  }
  .buildit-input-error {
    color: #E81C1C ;
    font-size: 12px;
    margin-top: 5px;
  }
}

