.ProjectFormValue-Header {
  background-color: rgba($color: #161A24, $alpha: 1.0);
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  
  >.description {
    margin-top: 10px;
    margin-left: 30px;
    width: 260px;
    font-size: 12px;
    line-height: 14px;
    color: #95E4B3;
  }

  >.show-button {
    margin-top: 10px;
    margin-right: auto;
    width: 100px;
    height: 30px;
    background-color: #161A24;
    color: #cccccc;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    
    &:hover {
      background-color: #273945;
    }
    .icon {
      margin-left: 3px;
      width: 18px !important;
      height: 18px !important;
      color: #999999;
      &.active {
        color: #48ddf2;
      }
    }
  }
}

.ProjectFormValue {
  padding: 30px 0px 30px 30px;
  margin-right: auto;
  display: flex;
  flex-flow: row wrap;
  width: 360px;
  .project-field {
    >.width-half {
      width: 175px;
    }
    margin-bottom: 30px;
    &.small {
      margin-bottom: 10px;
    }
  }

  .building-stories-wrap {
    width: 360px;
    display: flex;
    flex-flow: row wrap;
  }
  .more-btn-wrap {
    width: 360px;
    display: flex;
    position: relative;
    .more-btn {
      margin-left: auto;
      margin-right: auto;
      width: 100px;
      margin-bottom: 30px;
      margin-top: 30px;
    }
    .law-btn {
      position: absolute;
      right: 0px;
      top: 50%;
      width: 100px;
      transform: translateY(-50%);
    }
  }
  .more-fields {
    display: flex;
    flex-flow: row wrap;
    width: 360px;
    .project-field-wrap {
      display: flex;
      flex-flow: column;
      >.title {
        font-size: 12px;
        font-weight: bold;
        color: #999999;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        line-height: 16px;
      }
      .info-icon {
        width: 16px;
        height: 16px;
        color: #999999;
        margin-left: 3px;
        cursor: pointer;
      }
      >.project-field-group {
        display: flex;
        flex-flow: row wrap;
        width: 360px;
        >.project-field {
          display: flex;
          margin-left: 0px;
        }
      }
    }
  }

}