.designer-download-page {
  display: flex;
  min-height: calc(100vh - 180px);
  background-color: #161A24;
  justify-content: center;
  align-items: center;

  >.bg-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    max-height: calc(100vh - 180px);
    min-height: 490px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    >.bg-img {
      width: 100%;
      opacity: 0.4;
    }
  }

  >.content-wrapper {
    z-index: 2;
    width: 1200px;
    height: 485px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    >.title {
      color: #eeeeee;
      font-size: 30px;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      line-height: 35px;
    }

    >.downloder-wrapper {
      width: 1200px;
      height: 420px;
      margin-top: 30px;
      background-color: rgba(#232732, 0.8);
      border-radius: 6px;
      display: flex;
      flex-direction: column;

      >.button-wrapper {
        height: fit-content;
        margin-left: auto;
        margin-right: auto;
        margin-top: 156px;
        justify-content: center;
        display: flex;
        flex-direction: column;
        >.title {
          margin-left: auto;
          margin-right: auto;
          font-size: 24px;
          line-height: 18px;
          color: #eeeeee;
        }
        >.sub-title {
          margin-left: auto;
          margin-right: auto;
          font-size: 14px;
          color: #cccccc;
          margin-top: 10px;
        }
        >.download-btn {
          font-size: 14px;
          width: 230px;
          padding-left: 20px;
          padding-right: 20px;
          margin-top: 20px;
          margin-left: auto;
          margin-right: auto;
          &.designer-download-btn {
            .icon {
              color: #232732;
            }
          }
        }
      }
      >.grey-line {
        margin-top: 85px;
        width: -1px;
        border: 1px solid #333333;
      }
    }
  }
}