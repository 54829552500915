.SearchPlaceInput {
  width: 360px;
  color: #ccc;
  >.search-wrapper {
    position: absolute;
    z-index: 1;
    border-top: none;
    box-sizing: border-box;
    width: inherit;
    max-height: 500px;
    overflow: auto;
    background-color: #0C111B;
    border: 1px solid #555;
    border-top: 0px;
    &.empty {
      >.header {
        font-size: 12px;
        color: #999;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        align-items: center;
      }
      >.body {
        font-size: 12px;
        color: #999;
        border-top: 1px solid #333;
      }
      >.footer {
        border-top: 1px solid #333;
        height: 40px;
        padding-left: 10px;
        padding-right: 10px;
        display: flex;
        flex-flow: row;
        align-items: center;
        color: #999;
        font-size: 12px;
        >.title {
          cursor: pointer;
        }
        >.delete {
          cursor: pointer;
          margin-left: auto;
        }
      }
      .item {
        padding-right: 10px;
        display: flex;
        flex-flow: row;
        align-items: center;
        >.address {
          width: 310px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .icon-btn {
          background-color: inherit;
          transition: none;
          .icon {
            color: #555;
          }
          &:hover {
            background-color: inherit;
            .icon {
              color: #ccc;
            }
          }
        }
      }
    }

    .item {
      box-sizing: border-box;
      padding: 0px 10px;
      height: 40px;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      background-color: inherit;
      font-size: 14px;
      color: #ccc;
      cursor: pointer;
      width: 100%;
      >.body {
        >.name {
          display: block;
          color: #ccc;
        }
        >.address {
          display: block;
          color: #999;
          font-size: 12px;
        }
      }

      .highlight {
        color: #48DDF2;
        background-color: inherit;
      }

      &:first-child {
        border-top: 0px;
      }
      &:hover, &.active {
        background-color: #273945;
      }


      &.place {
        height: 58px;
      } 
      &.address {

      }
      &.geocode {

      }
    }
    
    >.place {
    
    }
    >.address {
      
    }

    >.loading-wrapper {
      width: 100%;
      height: 40px;
      align-items: center;
      display: flex;
      justify-content: center;
      >.loading {
        width: 18px !important;
        height: 18px !important;
        color: #ccc;
        background-color: inherit;
      }
    }
  }
}