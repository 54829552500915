.EnergyPage {
  background-color: #1A1E28;
  display: flex;
  flex-flow: column;
  min-height: calc(100vh - 280px);
  padding-bottom: 100px;
  margin-left: auto;
  margin-right: auto;
  width: 80%;

  >.tabs {
    width: 400px;
  }

  .run-button {
    width: 200px;
    margin-top: 20px;
  }

  >.result-page {
    //background-color: lightslategrey;
    background-image: url("/img/login_background.jpg");
    background-position: 50% 50%;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    >.full-content {
      background-color: rgba($color: #232732, $alpha: 0.75);
      
      >.button-layer {
        display: flex;
        justify-content: flex-end;
      }
  
      .title {
        //background-image: linear-gradient(to right, #48ddf2, #95e4b3);
        margin-top: 20px;
        width: 80%;
        background-image: linear-gradient(to right, #95e4b3, #48ddf2);
        //border: 1px solid #48ddf2;
        margin-left: auto;
        margin-right: auto;
        align-items: center;
        display: flex;
        border-radius: 6px;
        color: #1A1E28;
      }
  
      .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        margin-top: 5px;
        color: #cccccc;
        >.head {
          //background-color: #aaaaaa;
        }
        >.content {
        }
      }
  
      .system-table-cell {
        display: flex;
        align-items: center;
        color: #cccccc;
        justify-content: center;
        &.header {
          background-color: #232732;
        }
        &.info {
          background-color: #1A1E28;
        }
      }
    }
  }
}