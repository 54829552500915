.ProjectImport {
  width: 420px;
  position: absolute;
  height: calc(100vh - 120px);
  z-index: 1000;
  background-color: #1a1e28;
  box-sizing: border-box;
  overflow-x: visible;

  >.header {
    padding: 20px 30px;
    //border-bottom: 1px solid #333;
    box-sizing: border-box;
    height: 220px;
    >.title {
      display: flex;
      flex-flow: row;
      margin-bottom: 10px;
      height: 30px;
      .close-btn {
        padding: 0px;
        color: #555;
      }
    }
    >.tab-wrap {
      >.button-wrapper {
        margin-top: 20px;
        width: 285px;
        height: 30px;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        >.upload-btn {
          width: 240px;
          height: 30px;
          align-items: center;
          font-size: 12px;
          font-weight: 400;
          line-height: 14px;
          padding-left: 10px;
          
          .icon {
            margin-right: auto;
            margin-left: 2px;
            width: 14px !important;
            height: 14px !important;
            color: inherit;
          }
        }
        >.refresh-btn {
          width: 40px;
          height: 30px;
          margin-left: 5px;
          .icon {
            width: 20px !important;
            height: 20px !important;
            color: inherit;
          }
        }
      }
      
      >.search-field {
        margin-top: 10px;
        width: 285px;
        margin-left: auto;
        margin-right: auto;
        .icon-btn {
          padding: 0px;
          .icon {
            color: #ccc;
          }
          .remove-icon {
            width: 14px !important;
            height: 14px !important;
            margin-right: 10px;
          }
        }
      }
    }
  }
  >.content {
    // margin-top: 10px;
    overflow-x: visible;
    box-sizing: border-box;
    height: calc(100vh - 60px - 220px);
    overflow-y: auto;
    padding: 0px 0px 20px 0px;
    >.empty-info {
      >.title {
        font-size: 14px; 
      }
      >.add-wrap {

      }
    }
    >.quick-access {
      display: flex;
      margin-bottom: 20px;
      >.router-link {
        >.btn {
          width: 130px;
        }
      }
    }
    .item {
      margin-left: 67.5px;
      &.compare {
        position: fixed;
        top: 230px;
        left: 430px;
        margin-left: 0px;
      }
    }
  }
  
}

.ProjectImportItem {
  &:first-child {
    margin-top: 10px;
  }
  &.selected {
    cursor: default;
    &:hover {
      background-color: #232732;
    }
    >.img-wrap {
      >.active {
        display: flex;
      }
    }
  }

  &.empty {
    width: 310px;
    height: 410px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: default;
    background-color: #232732;
    color: #999;
    &:hover {
      background-color: #232732;
    }
  }

  &:hover {
    background-color: #273945;
    >.img-wrap {
      >.active {
        display: flex;
      }
    }
  }

  cursor: pointer;
  background-color: #232732;
  margin-left: auto;
  margin-right: auto;
  border-radius: 6px;
  width: 285px;
  margin-bottom: 20px;

  >.header {
    color: #ccc;
    font-size: 14px;
    padding: 20px 10px 10px 10px;
    display: flex;
    flex-flow: row;
    align-items: center;
    >.checked {
      display: flex;
      flex-flow: row;
      align-items: center;
      color: #95e4b3;
      >.check-icon {
        color: #95e4b3;
        margin-right: 5px;
      }
    }
  }
  >.img-wrap {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 285px;
    height: 285px;

    img {
      width: 285px;
      height: 285px;
      background-color: rgb(36,43,53);
    }
    >.active {
      display: none;
      position: absolute;
      margin: auto;

      >.add-icon {
        width: 50px;
        height: 50px;
        color: #48ddf2;
      }
      >.check-icon {
        width: 50px;
        height: 50px;
        color: #95e4b3;
      }
    }
  }
  >.info {
    display: flex;
    flex-flow: column;
    padding: 10px 10px 20px 10px;
    >.name {
      color: #ccc;
      font-size: 14px;
      margin-bottom: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space:nowrap;
    }
    >.key-value {
      display: flex;
      align-items: center;
      font-size: 12px;
      height: 20px;
      >.key {
        color: #999;
      }
      >.value {
        color: #ccc; 
        margin-left: auto;
      }
    }
  }
  
}

// .ProjectImportItem {
//   &.selected {
//     &:hover {
//       background-color: #232732;
//       cursor: default;
//     }
//     >.img-wrap {
//       >.active {
//         display: flex;
//       }
//     }
//   }
//   cursor: pointer;
//   background-color: #232732;
//   margin-left: auto;
//   margin-right: auto;
//   padding: 30px;
//   margin-bottom: 20px;
//   width: 310px;
//   box-sizing: border-box;
//   border: solid 1px #333333;
//   &:hover {
//     background-color: #383b45;
//     >.img-wrap {
//       >.active {
//         display: flex;
//       }
//     }
//   }
//   >.title {
//   }
//   >.img-wrap {
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     position: relative;
//     >.active {
//       display: none;
//       position: absolute;
//       margin: auto;

//       >.add-icon {
//         width: 50px;
//         height: 50px;
//         color: #48ddf2;
//       }
//       >.check-icon {
//         width: 50px;
//         height: 50px;
//         color: #95e4b3;
//       }
//     }
//     >img {
//       border: solid 1px #333333;
//       margin-top: 10px;
//       margin-bottom: 10px;
//       width: 248px;
//       height: 248px;
//       background-color: #232732;
//     }
//   }
//   >.info {
//     >.info-wrap {
//       display: flex;
//       flex-flow: row;
//       align-items: center;
//       >.key {
//         width: 80px;
//       }
//     }
//   }
  
// }