.field,
.house,
.core {
  margin: 20px auto 0px auto;
  width: 360px;
  color: #eeeeee;

  > .nameDiv {
    display: flex;
    flex-flow: row;
    font-size: 12px;
    align-items: center;
    margin-bottom: 10px;

    > .closeIcon {
      margin-left: auto;
      width: 20px;
      height: 20px;
      color: #555555;
      cursor: pointer;
    }

    > .showIcon {
      width: 18px;
      height: 18px;
      color: #999;
    }
    > .notice {
      margin-left: 10px;
      font-size: 12px;
      color: #f37b7b;
      font-family: "Noto Sans KR";
    }
  }

  .buttonDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    font-size: 14px;
    color: #ccc;

    button {
      width: 175px;
      height: 40px;
      border: solid 1px #333333;
      color: #cccccc;
      border-radius: 6px;
      padding-left: 10px;
      justify-content: end;
      > .MuiButton-label {
        font-size: 14px;
        font-family: "Noto Sans KR";
      }

      &.unselected{
        color: #cccccc;
      }

      &.selected {
        background-color: #eee;
        color: #232732;
      }
    }
    
    > button:hover{
      background-color: #eee;
      color: #232732;
    }
    > button:after,
    > button:before {
      background-color: #232732;
    }

    .CheckIcon {
      color: #555555;
      width: 20px;
      height: 20px;
      margin: 0 10px;

      &.checked {
        color: #95e4b3;
      }
    }

    > .houseList {
      display: flex;
      flex-flow: column;
    }

    &.alignTop {
      align-items: unset;
    }
  }

  .inputValues {
    display: flex;

    &.hidden {
      display: none;
    }
  }

  .extraMenu {
    position: absolute;
    margin-right: 10px;
    right: 0;
    font-size: 12px;
    color: #cccccc;
    text-align: end;
    cursor: pointer;
  }

  > .Line {
    width: 100%;
    height: 1px;
    margin: 10px 0px;
    background: #555555;
  }
}
