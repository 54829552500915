.RegistrationUser {
  background-color: #161A24;
  height: 100%;
  >.register-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    >a {
      >.logo {
        position: absolute;
        left: 30px;
        top: 18px;
      }
    } 
    >.register-form {
      width: 360px;
      >.header {
        font-size: 30px;
        color: #eee;
        line-height: 125%;
        margin-bottom: 20px;
      }
      >.register-info {
        margin-top: 25px
      }
      >.input-wrap {
        >.RegisterInput {
          .required-list {
            display: flex;
            flex-flow: row;
            align-items: center;
            margin-bottom: 5px;
            >.required-item {
              color: #555;
              >.icon {
                color: #555;
                width: 14px;
                height: 14px;
                margin-right: 3px;
              }
              font-size: 12px;
              display: flex;
              flex-flow: row;
              align-items: center;
              margin-right: 10px;

              &.true {
                color: #95E4B3;
                >.icon {
                  color: #95E4B3;
                }   
              }
            }
          }
          .icon {
            color: #555;
            &.true {
              color: #95E4B3;
            }
            &.error {
              color: #E81C1C
            }
          }
        }
        >.register-input {
          &:nth-child(4) {
            margin-top: 30px;  
          }
          margin-top: 10px;
          width: 360px;
        }
        // >.input-form {
          
          
        //   input {
        //     height: 40px;
        //     width: 360px;
        //     background-color: #0c111b;
        //     box-sizing: border-box;
        //     padding: 10px;
        //     font-size: 14px;
        //     color: #999;
        //   }
        // }
      }
      >.email-wrap {
        margin-top: 30px;
        width: 360px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #333;
        color: #48ddf2;
        font-size: 18px;
      }
      >a {
        .login-btn {
          margin-top: 25px;
          width: 360px;
        }
      }
      >.policy-wrap {
        padding-top: 30px;
        >.wrap {
          font-size: 12px;
          box-sizing: border-box;
          height: 30px;
          border-bottom: 1px solid #333;
          display: flex;
          align-items: center;
        }
        >.all {
          font-size: 14px;
          color: #eee;
        }
        >.use, >.personal, >.personal2 {
          color: #ccc;
          >.action {
            display: flex;
            flex-flow: row;
            align-items: center;
            text-decoration: underline;
            >div {
              cursor: pointer;
            }
          }
        }
      }
      >.sign-up-wrap {
        padding-top: 30px;
        .sign-up-btn {
          width: 360px;
        }
      }
    }
    >.footer {
      width: 360px;
      position: absolute;
      bottom: 30px;
      display: flex;
      flex-flow: row;
      color: #999;
      font-size: 12px;
      align-items: center;
      a {
        color: #999;
        text-decoration: none;
        white-space: pre;
      }
    }
  }
  >.dim {
    height: calc(100vh - 60px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 60px;
    z-index: 999999;
    >.circular-progress {
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 60px);
      color: #eee;
    }
  }
}


#root {
  .bg-primary {
    background-color: #1A1E28
  }
  .bg-secondary {
    background-color: #232732; 
  }
}