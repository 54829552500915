.ProjectIcon {
  display: flex;
  .svg {
    color: inherit;
  }
  >.status-text {
    &.config {
      color: #999;
    }
    &.waiting, &.running {
      color: #9E7F51;
    }
    &.finished {
      color: #95E4B3;
    }
    &.failed {
      color: #9E5151;
    }
  }
  >.status-icon {
    &.config {
      color: #999;
    }
    &.waiting, &.running {
      color: #9E7F51;
    }
    &.finished {
      color: #95E4B3;
    }
    &.failed {
      color: #9E5151;
    }
  }
}