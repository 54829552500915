.ProjectFormDetail {
  position: absolute;
  display: flex;
  right: 0px;
  height: calc(100vh - 60px);
  box-sizing: border-box;
  z-index: 101; // naver logo 100

  .detail-config-btn-tooltip {
    position: absolute;
    left: -120px;
    .detail-config-btn {
      
      background-color: #161A24;
      color: #ccc;
      font-size: 12px;
      box-sizing: border-box;
      width: 120px;
      height: 40px;
      border-radius: 6px 0px 0px 6px;
      &.error-field {
        color: #E81C1C !important;
        .icon {
          color: #E81C1C !important;
        }
      }
      &:hover {
        background-color: #161A24;
        color: #eee;
      }
    }
  }

  .detail-config-wrap {
    width: 420px;
    background-color: #161A24;
    display: flex;
    flex-flow: column;
    border-left: 1px solid #333333;
    border-right: 1px solid #333333;

    >.header {
      padding: 20px 30px;
      border-bottom: 1px solid #333333;
      .title { 
        text-align: center;
        margin-bottom: 15px;
        display: flex;
        flex-flow: row;
        align-items: center;
        justify-content: center;
        position: relative;
        
        >.tooltip {
          position: absolute;
          right: 0px;
        }
      }
      .detail-config-type {
        .tabs {
          .tab {
            &.error-field {
              color: #E81C1C !important;
            }
          }
        }
      }
    }
    >.content {
      overflow-y: auto;
      height: 100vh;
    }
  }
}