.ProjectPayment {
  background-color: #1A1E28;
  padding-bottom: 100px;
  >.breadcrumb-wrap {
    background-color: #161A24;
    >.content {
      width: 1200px;
      margin: auto;
      >.breadcrumb {
        margin-left: auto;
        width: 300px;
        text-align: right;
        padding-top: 20px;
        color: #999;
      }
    }
  }
  >.summary {
    margin-top: 10px;
  }
  >.payment-wrapper {
    background-color: #161A24;
    width: 100%;
    >.content {
      padding-top: 20px;
      margin-left: auto;
      margin-right: auto;
      width: 1200px;
      display: flex;
      flex-flow: column;
      >.title {
        font-size: 30px;
        color: #eee;
      }
      >.pay-info-wrapper {
        >.title {
          color: #48DDF2;
          font-size: 18px;
          height: 50px;
          box-sizing: border-box;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #555;
        }
        >.body {
          padding: 20px 0px;
          display: flex;
          flex-flow: row;
          align-items: center;
          >.pay-wrap {
            background-color: #232732;
            display: flex;
            flex-flow: column;
            >.project-setting {
              border-bottom: 1px solid #333;
              width: 672px;
              padding: 20px 0px 10px 0px;
              margin: 0px 20px;
              // box-sizing: border-box;
              display: flex;
              >.project-type-wrap {
                width: 100%;
                height: 40px;
                display: flex;
                align-items: center;
                >.project-type-btns {
                  margin-left: auto;
                  >.project-type {
                    &.select {
                      border-color: #95e4b3;
                      color: #95e4b3;
                      .icon {
                        color: #95e4b3;
                      }
                    }
                    border: 1px solid #ccc;
                    box-sizing: border-box;
                    width: 120px;
                    padding: 0px;
                    height: 30px;
                    font-size: 14px;
                    color: #ccc;
                    .type-btn-wrap {
                      width: 100%;
                      display: flex;
                      align-items: center;
                      text-align: left;
                      .icon-wrap {
                        width: 40px;
                        display: flex;
                        padding: 0px 10px;
                        box-sizing: border-box;
                        margin-left: auto;
                        .icon {
                        }
                      }
                      .type {
                        height: 28px;
                        display: flex;
                        align-items: center;
                        margin-left: 10px;
                      }
                    }
                    >.icon-wrap {
                    }
                  }
                }
              }
              >.reports-number {
                margin-left: auto;
                .reports-number-input {
                  width: 120px;
                }
              }
            }
            >.pay-info {
              border-radius: 6px;
              display: flex;
              flex-flow: row;
              align-items: center;
              padding: 20px 0px;
              width: 712px;
              >.info-wrap {
                margin-bottom: auto;
                width: 346px;
                &.left {
    
                }
                &.right {
                  
                }
                >.key-value {
                  padding: 0px 10px;
                  margin: 0px 10px;
                  height: 40px;
                  display: flex;
                  align-items: center;
                  >.key {
                    font-size: 14px;
                    color: #999;
                  }
                  >.value {
                    margin-left: auto;
                    color: #ccc;
                    font-size: 14px;
                    &.large {
                      font-size: 20px;
                    }
                  }
                  &.total {
                    border-top: 1px solid #333;
                    >.key, >.value {
                      color: #48DDF2;
                    }
                  }
                }
              }
              >.info-wrap-divider {
                border: 0.5px solid #333;
                height: 120px;
                margin: 0px 9.5px;
              }
            }
          }
          >.pay-btns {
            display: flex;
            margin-left: auto;
            flex-flow: column;
            width: 366px;
            margin-top: auto;
            >.after-pay {
              width: 366px;
              height: 100px;
              font-size: 18px;
              color: #ccc;
              display: flex;
              text-align: center;
              align-items: center;
              justify-content: center;
            }
            >.agree-wrap {
              cursor: pointer;
              display: flex;
              align-items: center;
              width: 100%;
            }
            >.pay-btn {
              margin-top: 20px;
              width: 100%;
            }
            >.back-btn {
              margin-top: 10px;
              width: 100%;
            }
          }
        }
      }
    }
  }

  .ProjectSummary {
    width: 1200px;
    margin-left: auto;
    margin-right: auto;
  }
}