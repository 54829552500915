.UserConfirm {
  width: 100%;
  height: 100%;
  background-color: #232732;
  >.user-confirm-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    >a {
      >.logo {
        position: absolute;
        left: 30px;
        top: 10px;
      }
    }
    >.footer {
      position: absolute;
      bottom: 30px;
      display: flex;
      flex-flow: row;
      color: #ccc;
      font-size: 12px;
      align-items: center;
      >div {
        margin-left: 35px;
        margin-right: 35px;
      }
    }
    >.user-confirm-body {
      padding-top: 30px;
      width: 360px;
      >.header {
        font-size: 30px;
        color: #eee;
      }
      >.info {
        padding-top: 25px;
        font-size: 18px;
        color: #ccc;
      }
      >.email-wrap {
        margin-top: 30px;
        width: 360px;
        height: 100px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        border: 1px solid #333;
      }
      >.options {
  
      }
      >.login-wrap {
        padding-top: 20px;
        .btn {
          width: 360px;
        }
      }
    }
  }
}