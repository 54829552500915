.foot {
  // position: absolute;
  width: 100%;
  height: 120px;
  // bottom: 0px;
  background-color: #1A1E28;;
  border-top: 1px solid #333;
  box-sizing: border-box;

  > .footContent {
    position: relative;
    font-size: 12px;
    width: 1200px;
    margin: 0 auto;

    color: #999999;
    .link {
      text-align: right;
      position: relative;
      top: 30px;
      a {
        color: #999999;
        text-decoration: none;
        white-space: pre;
      }
      span {
        margin: 0 5px;
      }
    }
    .text {
      text-align: right;
      position: relative;
      top: 35px;
    }
    .logo{
      width: 145.4px;
      height: 30px;
    }
  }
}
