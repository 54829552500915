.MyPageHead {
  width: 100%;
  height: 120px;
  background-color: #161A24;

  .content {
    width: 1200px;
    margin: 0 auto;

    .pageInfoLayer {
      width: 100%;
      height: 15px;
      margin-top: 22px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      color: #999999;
  
      .nextIcon {
        margin-left: 5px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }

    .buttonLayer {
      justify-content: space-between;
      margin-top: 23px;
      flex-direction: row;
      display: flex;
      .title {
        width: fit-content;
        display: flex;
        font-size: 30px;
        height: 40px;
        align-items: center;
        font-family: Noto Sans KR;
      }
      .buttons {
        width: fit-content;
        display: flex;
        justify-content: flex-end;
        .emailButton {
          width: fit-content;
          min-width: 174px;
          height: 40px;
          background-color: #232732;
          color: #ccc;
          border-radius: 6px;
          font-family: Noto Sans KR;
          text-transform: none;
          font-size: 14px;
          line-height: 16px;
          padding: 20px;
          .text {
            font-weight: 100;
          }
        }

        .pointButton {
          height: 40px;
          background-color: #232732;
          border-radius: 6px;
          align-items: center;
          display: flex;
          justify-content: flex-start;
          font-family: Noto Sans KR;
          margin-left: 10px;
          
          .svg {
            margin-left: 10px;
            display: flex;
            align-items: center;
            >object {
              width: 16px !important;
              height: 16px !important;
            }
          }
          .text {
            font-size: 14px;
            line-height: 17px;
            color: #999999;
            margin-left: 5px;
            margin-top: 1px;
            font-family: Noto Sans KR;
            font-weight: 100;
          }
          .point {
            text-align: right;
            margin-right: 15px;
            margin-left: 20px;
            font-size: 14px;
            line-height: 21px;
            color: #CCCCCC;
            margin-left: 10px;
            font-family: Roboto;
            font-weight: 100;
          }
        }

        .switchTabs {
          display: flex;
          background-color: #383B45;
          height: 36px;
          border-radius: 6px;
          padding: 2px;
          align-items: center;
          text-align: center;
          margin-left: 10px;
          

          .switchButton {
            font-size: 12px;
            line-height: 128.7%;
            height: 36px;
            background-color: #383B45;
            color: #CCCCCC;
            padding: 0px;
            font-family: Noto Sans KR;

            &:hover {
              background-color: #60626a;
              color: #eee;
            }

            &.enable {
              background-color: #EEEEEE;
              color: #232732;
            }
          }
        }
      }
    }
  }
}
