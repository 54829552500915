.ProjectFormHousingChart {
  height: calc(100vh - 60px);
  width: 356px;
  background-color: #1a1e28;
  box-sizing: border-box;
  >.content {
    >.header {

      height: 70px;
      display: flex;
      padding-left: 30px;
      align-items: center;
      color: #ccc;
      font-size: 18px;
      border-bottom: 1px solid #333;
      box-sizing: border-box;
    }
    >.chart-wrapper {
      padding: 20px 30px;
      >.HousingChart {
        // override
        
        .C3Chart-legend {
          height: calc(100vh - 500px);
          overflow-y: auto;
          padding: 5px 0px;
          justify-content: unset;
          >.legend-item {
            width: 276px;
            &.focused {
              >.legend-id, >.proportion {
                color: #ccc;
              }
            }
            cursor: pointer;
            >.symbol {
              width: 10px;
              height: 10px;
            }
            >.legend-id {
              margin-left: 10px;
              width: 170px;
              color: inherit;
              display: flex;
              flex-flow: row;
              align-items: center;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            >.proportion {
              width: 40px;
              margin-left: auto;
              text-align: right;
            }
          }
        }
      }
    }
  }
}