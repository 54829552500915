.not-found-page {
  display: flex;
  min-height: calc(100vh - 180px);
  background-color: #161A24;
  justify-content: center;
  align-items: center;

  >.bg-wrapper {
    top: 0;
    left: 0;
    width: 100%;
    max-height: calc(100vh - 180px);
    min-height: 490px;
    position: absolute;
    z-index: 1;
    overflow: hidden;
    >.bg-img {
      width: 1920px;
      height: 906px;
      opacity: 0.2;
      background-position: 50% 60%;
      background-repeat: no-repeat;
      background-image: url("/img/galaxy.png");
    }
  }

  >.content-wrapper {
    z-index: 2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    >.title {
      color: #eeeeee;
      font-size: 60px;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      line-height: 87px;
    }
    >.sub-title {
      color: #eeeeee;
      font-weight: bold;
      font-size: 24px;
      line-height: 35px;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 20px;
    }
    >.description {
      color: #cccccc;
      font-size: 18px;
      line-height: 26px;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
      margin-top: 5px;
      text-align: center;
    }
    >.home-button {
      color: #232732;
      font-size: 18px;
      line-height: 26px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 32px;
      width: 200px;
      background-image: linear-gradient(to right, #66dfd9,#95e4b3 );
    }
  }
}