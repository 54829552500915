.Login {
  width: 100%;
  height: 100%;
  background: url('/img/login_background.jpg');
  
  >.dim {
    height: calc(100vh - 60px);
    width: 100%;
    background-color: rgba(0, 0, 0, 0.25);
    position: absolute;
    top: 60px;
    z-index: 999999;
    >.circular-progress {
      position: absolute;
      left: calc(50% - 30px);
      top: calc(50% - 60px);
      color: #eee;
    }
  }

  .login-wrapper {
    background-color: rgba(35, 39, 50, 0.9);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    >a {
      >.logo {
        position: absolute;
        left: 30px;
        top: 18px;
      }
    }
    >.footer {
      width: 360px;
      position: absolute;
      bottom: 30px;
      display: flex;
      flex-flow: row;
      color: #999;
      font-size: 12px;
      align-items: center;
      a {
        color: #999;
        text-decoration: none;
        white-space: pre;
      }
    }
    >.login-form {
      width: 360px;
      margin: auto;
      >.header {
        font-size: 30px;
        color: #eee;
        line-height: 125%;
      }
      >.download-description {
        margin-top: 10px;
        color: #48ddf2;
        font-size: 14px;
        font-family: "Roboto";
        font-weight: 400;
      }
      >.login-btn-wrap {
        padding-top: 10px;
        .login-btn {
          width: 360px;
          box-sizing: border-box;
        }
      }
      >.register-wrap {
        margin: auto;
        padding-top: 10px;
        .register-btn {
          width: 360px;
          color: #eeeeee;
          font-size: 14px;
        }
      }
      >.options {
        display: flex;
        flex-flow: row;
        align-items: center;
        color: #eee;
        padding-top: 10px;        
        >.forgot-btn {
          cursor: pointer;
        }
      }
      >.input-wrap {
        >.login-input {
          color: #cccccc;
          width: 360px;
          margin-top: 10px;
        }
        >.input-form {
          margin-top: 10px;
          height: 40px;
          width: 360px;
          background-color: #f5f5f5;
          border-radius: 0px;
          input {
            height: 40px;
            box-sizing: border-box;
            padding: 10px;
            font-size: 14px;
          }
        }
      }
    }
  }
}