.building {
  margin: 11px auto 9px auto;
  width: 360px;
  color: #eeeeee;

  > .nameDiv {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    .name {
      display: flex;
      width: 100%;
      font-size: 12px;
      align-items: center;
      color: #48ddf2;
    }

    > .closeIcon {
      margin-left: auto;
      width: 20px;
      height: 20px;
      color: #555555;
      cursor: pointer;
    }
  }

  .buildingBody {
    .roomAndCore {
      flex: 1;
      display: flex;
      flex-flow: column;

      > button {
        flex: 0 0 auto;
        border: 0;
        font-size: 12px;
        width: 175px;
        height: 30px;
        border-radius: 6px;
        color: #cccccc;
        background-color: #383b45;
        margin-left: auto;
        margin-top: 10px;
      }
      button:hover {
        background-color: #60626a;
      }
      button:after,
      button:before {
        background-color: #232732;
      }
    }
  }
  > .Line {
    width: 100%;
    height: 1px;
    margin-top: 10px;
    background: #555555;
  }
}
