.UnconfirmedUser {
  background-color: #232732;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  >.wrapper {
    width: 360px;
    >.title {
      line-height: 120%;
      font-size: 30px;
      color: #eee;
    }
    >.register-info {
      margin-top: 35px;
    }
    >.email-wrap {
      width: 360px;
      height: 100px;
      color: #48DDF2;
      font-size: 18px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      border: 1px solid #333333;
      box-sizing: border-box;
    }
    >a {
      >.login-btn {
        margin-top: 20px;
        width: 360px;
        height: 40px;
      }
    }
  }
}