.highlight {
  color: red;
}

.testtest {
  .test {
    margin-bottom: 0px;
    margin-top: 0px;
  }
  .test-detail {
    background-color: #ddd;
    align-items: center;
    padding: 16px;
  }
}

.test-tabs {
  width: 303px;
  .test-tab {
    border: 1px solid black;
    min-width: 100px;
  }

  .test-tab[aria-selected="true"] {
    background-color: black;
    color: white;
  }
}


.test-tabs2 {
  width: 64px;
  >div>div>div {
    flex-flow: column;
  }
  .test-tab2 {
    border: 1px solid black;
    min-width: 64px;
    height: 64px;
  }

  .test-tab2[aria-selected="true"] {
    border-right: 0px;
  }
}

.test4 {
  .card {
    width: 200px;
  }
}

.Test {
  display: flex;
  flex-flow: row;
  cursor: url("/img/cursor.png") 0 0, auto;

}

.test5 {
  border: 1px solid #ddd;
  width: 100px;
}

.test6 {
  .drag-box {
    display:flex;
    flex-flow: row;
    .drag-item {
      display:flex;
      flex-flow: row;
    }
  }
}


.ttt1 {
  .tttest {
    border-radius: 15px;
    background-color: white;
    border: 1px solid rgb(209, 111, 62);
    position: relative;
    width: 180px;
    height: 32px;
    cursor: pointer;
  
    .ttest {
      border-radius: 14px;
      padding: 0px 20px;
      color: black;
      position: absolute;
      height: 30px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    
      &.left {
        left: 0px;
      }
      &.right {
        right: 0px;
      }
    
      &.active {
        border: 1px solid rgb(209, 111, 62);
        background-color: rgb(209, 111, 62);
        color: white;
        z-index: 1;
      }
    }
    
  }
}

.ttt2 {
  .tttest {
    border-radius: 15px;
    background-color: white;
    border: 1px solid rgb(209, 111, 62);
    position: relative;
    width: 130px;
    height: 32px;
    cursor: pointer;
  
    .ttest {
      border-radius: 14px;
      padding: 0px 20px;
      color: black;
      position: absolute;
      height: 30px;
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
    
      &.left {
        left: 0px;
      }
      &.right {
        right: 0px;
      }
    
      &.active {
        border: 1px solid rgb(209, 111, 62);
        background-color: rgb(209, 111, 62);
        color: white;
        z-index: 1;
      }

      span {
        user-select: none;
      }
    }
    
  }
}
.jjjj {
  .jTest {
    border: 1px solid #eeeeee;
  }
  .jTest:hover {
    border: 1px solid #b9b2b2;
  }
}

.TEST {
  .tabs {
    width: 200px;
  }
  .ProjectInput {
    width: fit-content;
    >.header {
      display: flex;
      flex-flow: row;
      >.title {
        color: #999;
        font-size: 12px;
        &.error {
          color: #E81C1C;
        }
        >.unit-select {
          margin-left: auto;
        }
      }
    }
    >.unit-select {
      margin-left: auto;
      font-size: 12px;
      .unit {
        width: 80px;
      }
    }
  }
  >.content {
    .icon-btn {
      padding: 0px;
      .icon {
        color: #ccc;
        width: 20px;
        height: 20px;
      }
      &.dec-btn {
        margin-right: 5px;
      }
    }
  }
  
}

.TestCardWrap {
  display: flex;
  align-items: center;
}

.TestCard {
  display: flex;
  flex-flow: column;
  width: 300px;
  background-color: white;
  margin-right: 20px;
  height: 100px;
  >.field {
    height: 20px;
    width: 100%;
    color: black;
    &.hover {
      background-color: blue;
      color: yellow;
    }
  }
  >.name {

  }
  >.years {
    
  }
}