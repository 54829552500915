.project-form-input-field {
  width: 72px;
  input {
    padding: 8px;
    font-size: 14px;
  }
}

.ProjectFormInputField {
  margin: 5px;
  display: inline-block;
}