#root {
  overflow: hidden;
  background-color: #1a1e28;
}
header {
  background-color: #232732;
  display: flex;
  height: 60px;

  .logo {
    margin-left: 30px;
  }

  .projectName {
    width: 100%;
    text-align: center;
    vertical-align: auto;
    color: #ccc;
    font-size: 14px;
    line-height: 16px;
    margin: auto;
  }

  > .buttons {
    position: absolute;
    right: 0;
    top: 5px;
    display: flex;
    flex-direction: row;
    margin: auto;
    margin-right: 30px;

    > .imageBox {
      position: relative;
      cursor: pointer;
      width: 40px;
      height: 40px;
      margin: 5px;
      background-color: #383b45;
      border-radius: 6px;
      display: flex;
      color: #ccc;

      > .tooltiptext {
        position: absolute;
        width: max-content;
        visibility: hidden;
        opacity: 0;
        cursor: default;
        background-color: #000000;
        color: #eeeeee;
        text-align: center;
        padding: 5px 10px;
        font-size: 12px;
        z-index: 2;
        bottom: -100%;
        left: 50%;
        transition: opacity 0.3s;
        transform: translate(-50%, 0%);
      }

      > .tooltiptext::after {
        content: "";
        position: absolute;
        bottom: 100%;
        left: 50%;
        margin-left: -5px;
        border-width: 5px;
        border-style: solid;
        border-color: transparent transparent #000000 transparent;
      }

      > .imageButton {
        margin: auto;
        // color: #eee;
        width: 20px;
        height: 20px;
      }
      &.hidden {
        display: none;
      }
    }

    .imageBox:hover {
      .imageButton {
        color: #95e4b3;
      }

      > .tooltiptext {
        visibility: visible;
        opacity: 1;
      }
    }
  }
}

.mainView {
  display: flex;
  .checkboxList {
    background-color: #161a24;
    width: 120px;

    .reportBox {
      height: 40px;
      padding-left: 22.5px;
      color: #ccc;
      display: flex;
      // margin: auto;

      > .checkbox {
        padding: 0px;
        padding-right: 7.5px;
        color: #ccc;
        svg {
          font-size: 20px;
        }

        &.checked {
          color: #95e4b3;
        }
      }
      > .label {
        margin-top: 10px;
        margin-left: 0;
        font-size: 12px;
      }
    }
  }
}

.lightControl {
  width: 215px;
  height: 164px;
  right: 43px;
  top: 70px;
  background-color: #222632;
  color: #eee;
  position: absolute;
  z-index: 1;

  .text {
    display: flex;
    font-size: 12px;
    font-style: normal;
    font-weight: bold;
    line-height: 17px;
    margin: 20px 0 0 20px;
    > span {
      margin-left: auto;
      margin-right: 20px;
      color: #95e4b3;
    }
  }
  .seasonButtons {
    display: flex;
    margin: 10px 0 0 20px;
    > .button {
      margin-right: 5px;
      text-align: center;
      font-size: 12px;
      line-height: 30px;
      width: 40px;
      height: 30px;
      background: #222632;
      border: 1px solid #555;
      box-sizing: border-box;
      border-radius: 6px;
      cursor: pointer;
    }
    & > .selected {
      color: #95e4b3;
      border: 1px solid #95e4b3;
    }
  }
  .slider {
    margin: 18px 0 0 20px;
    -webkit-appearance: none;
    width: 175px;
    height: 4px;
    background: #555555;
    outline: none;
    opacity: 1;
  }

  .slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #95e4b3;
    cursor: pointer;
  }

  .slider::-moz-range-thumb {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #95e4b3;
    cursor: pointer;
  }
}

.Visualizer {
  position: relative;
  display: flex;
  // height: calc(100% - 60px);

  .interface {
    position: absolute;
    background-color: rgba(0.2, 0.2, 0.2, 0.2);
    top: 0px;
    left: 0px;
    height: 100%;
    width: 50px;

    > button {
      padding: 0px;
      border: 0px;
      background: none;
      outline: none;

      &.handCursor {
        cursor: pointer;
      }

      > img {
        width: 50px;
        height: 50px;
      }
    }
  }

  .lightDir {
    position: absolute;
    -webkit-appearance: none;
    width: 100%;
    height: 15px;
    border-radius: 5px;
    background: #d3d3d3;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
  }
  .lightDir::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }
  .lightDir::-moz-range-thumb {
    width: 25px;
    height: 25px;
    border-radius: 50%;
    background: #4caf50;
    cursor: pointer;
  }

  .canvases {
    display: flex;
    flex-flow: row wrap;
    width: calc(100% - 120px);
    .Canvas {
      position: relative;

      .rightTop {
        position: absolute;
        right: 10px;
        top: 10px;

        .compassButton {
          width: 40px;
          height: 40px;
          padding: 0px;
          border: 0px;
          margin-bottom: 10px;
          background: none;
          cursor: pointer;
          outline: none;
        }

        .imageButton {
          cursor: pointer;
          width: 40px;
          height: 40px;
          background-color: #383b45;
          border-radius: 6px;
          display: flex;
          margin-bottom: 10px;
          .image {
            width: 24px;
            height: 24px;
            margin: auto;
            color: #ccc;
          }

          &.hidden {
            visibility: hidden;
          }

          &.active {
            .image {
              color: #95e4b3;
            }
          }

          .tooltiptext {
            position: absolute;
            width: max-content;
            visibility: hidden;
            opacity: 0;
            cursor: default;
            background-color: #000000;
            color: #eeeeee;
            text-align: center;
            padding: 5px 10px;
            font-size: 12px;
            z-index: 2;
            right: 120%;
            transition: opacity 0.3s;
            transform: translate(0, 25%);
          }

          .tooltiptext::after {
            content: "";
            position: absolute;
            left: 100%;
            top: 35%;
            border-width: 4px;
            border-style: solid;
            border-color: transparent transparent transparent #000000;
          }
        }

        .imageButton:hover {
          .image {
            color: #95e4b3;
          }
          > .tooltiptext {
            visibility: visible;
            opacity: 1;
          }
        }

        .reportNum {
          text-align: center;
        }
      }

      .leftTop {
        position: absolute;
        width: 165px;
        height: 110px;
        left: 10px;
        top: 10px;
        background-color: rgba(22, 26, 36, 0.5);
        border-radius: 6px;
        color: #eee;
        display: flex;
        flex-direction: column;
        -webkit-user-select: none;
        -webkit-user-drag: none;
        -webkit-app-region: no-drag;

        .titleDiv {
          height: 40px;
          display: flex;
          .title {
            font-size: 14px;
            margin: auto;
            margin-left: 10px;
          }
        }
        .parameter {
          display: flex;
          height: 20px;
          color: #ccc;
          font-size: 12px;
          .name {
            padding-left: 10px;
            width: 80px;
          }
          .value {
            margin: auto;
            margin-right: 10px;
            right: 0px;
          }
        }
      }

      canvas {
        display: inherit;
        outline: none;
      }

      &.mouseOver {
        border: 2px solid #48ddf2;
      }
    }
  }
}

.modalBody {
  div {
    > div {
      width: 985px;
      height: 640px;
      max-width: 985px;
      background-color: #1a1e28;
    }
  }

  .modal-title {
    background-color: #1a1e28;
    box-sizing: border-box;
    width: 985px;
    height: 50px;
    padding: 30px 40px 0 30px;
    font-size: 18px;
    color: #eee;
    display: flex;
    .title {
      width: auto;
      height: 20px;
    }

    .icon {
      color: #555555;
      margin-left: auto;
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .project_info {
    display: flex;
    max-width: 955px;
    height: auto;
    padding-top: 21px;
    padding-left: 30px;

    .info_parameter {
      width: 175px;
      height: 40px;
      display: flex;
      flex-direction: column;
      // padding-left: 10px;

      .info_title {
        width: auto;
        height: 20px;
        font-size: 12px;
        color: #999;
      }

      .info_value {
        width: auto;
        font-size: 14px;
        color: #ccc;
        height: 20px;
      }

      &.w175 {
        width: 175px;
      }

      &.w360 {
        width: 360px;
      }

      &.rb {
        border-right: 1px solid #333;
        margin-right: 10px;
      }
    }
  }

  .dividingLine {
    margin-left: 30px;
    margin-top: 21px;
    height: 1px;
    width: 915px;
    border-top: solid 1px #555555;
  }

  .info {
    width: auto;
    margin-left: 30px;
    margin-top: 30px;
    display: flex;
    flex-direction: column;

    .row {
      display: flex;
      width: auto;
      height: auto;

      .cell {
        width: 175px;
        height: 20px;
        padding: 10px 0 10px 0;
        margin-right: 10px;
        font-size: 14px;
        color: #ccc;

        &.reportNum {
          font-size: 18px;
          color: #48ddf2;
        }
      }
    }
  }
}

.loading {
  width: 100%;
  height: 100%;
  background: linear-gradient(rgba(35, 39, 50, 0.75), rgba(35, 39, 50, 0.75)), url("/img/login_background.jpg");
  background-size: cover;
  background-position: center center;

  header {
    position: relative;
    z-index: 2;
  }

  .information {
    position: absolute;
    transform: translate(-50%, -50%);
    top: 50%;
    left: 50%;
    text-align: center;
    z-index: 1;

    .progress {
      height: 244px;
      animation: rotation 1.33s infinite linear;
    }
    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(359deg);
      }
    }

    .centerWord {
      transform: translate(-50%, -100%);
      top: 50%;
      left: 50%;
      position: absolute;
      width: 128px;
      font-family: Noto Sans KR;
      font-style: normal;
      font-weight: bold;
      font-size: 24px;
      line-height: 28px;
      text-align: center;
      color: #eeeeee;
    }

    .loadingMessage {
      padding-top: 30px;
      font-family: Noto Sans KR;
      font-style: normal;
      // font-weight: bold;
      font-size: 18px;
      line-height: 26px;
      text-align: center;
      color: #48ddf2;
      > .white {
        color: white;
      }
    }
  }
}
