.ProjectForm {
  display: flex;
  flex-flow: column;
  width: 100%;
  min-width: 1200px;
  height: calc(100vh);
  position: relative;

  >.transparent-dim {
    z-index: 1100;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  >.tutorial-wrap {
    z-index: 1500;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    >.tutorial {
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      position: absolute;
      img {
        width: auto;
        height: auto;
      }
      .close-btn {
        position: absolute;
        top: 20px;
        right: 20px;
        .close-icon {
          width: 40px;
          height: 40px;
        }

      }
      .never-close-btn {
        position: absolute;
        bottom: 26px;
        right: 50px;
        font-size: 14px;
        color: #666666;
        .close-icon {
          color: #999;
          width: 20px;
          height: 20px;
        }
      }
      .guide-btn {
        position: absolute;
        bottom: 79px;
        left: 83px;
        background-color: #232732;
        color: #48DDF2;
        font-size: 14px;
        width: 210px;
        box-sizing: border-box;
        padding: 10px;
        height: 40px;
        a {
          display: flex;
          color: inherit;
          text-decoration: none;
          width: inherit;
        }
        .icon {
          color: #48DDF2;
        }
      }
    }
  }

  >.content {
    min-width: 1200px;
    position: relative;
    display: flex;
    flex-flow: row;
    height: calc(100vh);
    >.config-btn {
      position: absolute;
      z-index: 1000;
      &.site-config-btn {
        background-color: #232732;
        color: #eeeeee;
        border-radius: 0px 6px 6px 0px;
        &.open-map {
          left:420px;
        } 
      }

      &.detail-config-btn {
        
      }
    }
  }

  transition-duration: 1s;
  
  .snack-bar {
    position: absolute;
    top: 80px;
    z-index: 0;
    padding: 0px;
    .msg {
      >div {
        display: flex;
        align-items: center;
      }
    }
  }
}



////////////////////////////

.ProjectFormHead {
  width: 100%;
  height: 60px;
  box-sizing: border-box;
  background-color: #232732;
  display: flex;
  flex-flow: row;
  border-bottom: 1px solid #333333;
  align-items: center;
  position: relative;
  >.logo {
    margin-left: 30px;
    cursor: pointer;
  }
  .title {
    position: absolute;
    z-index: 1200;
    left: 50%;
    top: 10px;
    transform: translate(-50%);
    box-sizing: border-box;
    font-size: 18px;
    color: #ccc;
    display: flex;
    flex-flow: row;
    align-items: center;
    
    >.project-name {
      width: fit-content;
      >.project-type {
        width: 110px;
        background-color: #232732;
        &:hover {
          background-color: #273945;
        }
        >.input-wrapper {
          border-color: #232732;
          &:hover {
            background-color: #273945;
          }
          &:focus {
            background-color: #232732;
          }
        }
      }
      >.project-name-explanation-wrap {
        
      }
      display: flex;
      flex-flow: row;
      align-items: center;
      .project_name {
        width: 600px;
        input {
          font-size: 18px;
          padding: 8px;
        }
      }
      .project-name-no-edit {
        max-width: 480px;
        height: 40px;
        display: flex;
        padding-left: 10px;
        padding-right: 10px;
        // justify-content: center;
        align-items: center;
        >.value {
          max-width: 470px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        &:hover {
          background-color: #273945;
          cursor: text;
        }
      }
      
    }
  }
  >.right {
    display: flex;
    flex-flow: row;
    margin-left: auto;
    align-items: center;
    .breadcrumb {
      color: #999999;
      margin-left: auto;
      margin-right: 30px;
    }
    >.project-config-btns {
      .run-btn {
        width: 140px;
      }
      display: flex;
      flex-flow: row;
      margin-right: 30px;
      .icon {
        color: #eee;
      }
    }
  }
  >.page-title {
    margin: auto;
    font-size: 18px;
    color: #ccc;
    position: absolute;
    width: 200px;
    text-align: center;
    left: 50%;
    transform: -100px;
  }
  .project-memo {
    position: absolute;
    top: 60px;
    right: 312px;
    z-index: 1200;
    .project-explanation {
      height: 500px;
      width: 400px;
      color: #ccc;
      font-size: 16px;
      z-index: 100000;
      display: flex;
      flex-flow: column;
      &:focus, &:hover {
        >.title {
        }
      }
    }
  }
  .dim {
    cursor: pointer;
    position: fixed;
    z-index: 999998;
    width: 100%;
    height: 200%;
  }
}

////////////////////////////


////////////////////////////

.ProjectTypeSelect {
  position: relative;
  height: 40px;
  font-size: 16px;
  display: flex;
  align-items: center;
  .select-input {
    display: flex;
    align-items: center;
    height: 40px;
    padding: 0px 10px;
    cursor: pointer;
    color: #95e4b3;
    &:hover {
      background-color: #273945;
    }

    &.none-hover {
      &:hover {
        background-color: inherit;
      }
      cursor: default;
    }
    .icon {
      margin-left: 5px;
      color: #95e4b3;
    }
  }
  .select-items {
    position: absolute;
    top: 60px;
    background-color: #232732;
    .item {
      padding: 10px;
      box-sizing: border-box;
      height: 40px;
    }
  }
}