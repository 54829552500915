.ResetPassword {
  width: 100%;
  height: 100%;
  background-color: #232732;
  .reset-password-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    >a {
      >.logo {
        position: absolute;
        left: 30px;
        top: 18px;
      }
    }
    >.reset-form {
      >.header {
        font-size: 30px;
        color: #eee;
        width: 360px;
      }
      >.input-wrap {
        padding-top: 25px;
        width: 360px;
        color: #ccc;
        font-size: 18px;
        >.info {
          padding-bottom: 20px;
          >.email {
            color: #48ddf2;
          }
        }

        .required-list {
          display: flex;
          flex-flow: row;
          align-items: center;
          margin-bottom: 5px;
          >.required-item {
            color: #999;
            >.icon {
              color: #555;
              width: 14px;
              height: 14px;
              margin-right: 3px;
            }
            font-size: 12px;
            display: flex;
            flex-flow: row;
            align-items: center;
            margin-right: 10px;

            &.true {
              color: #95E4B3;
              >.icon {
                color: #95E4B3;
              }   
            }
          }
        }
        .icon {
          color: #555;
          &.true {
            color: #95E4B3;
          }
          &.error {
            color: #E81C1C
          }
        }

        >.input {
          width: 360px;
          margin-top: 10px;
        }
      }
      >.btn-wrap {
        margin-top: 15px;
        >.btn {
          width: 360px;
        }
      }
    }
  }
}