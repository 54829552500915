.ConstructionLineInfo {
  .line {
    display: "flex";
    align-items: "center";
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0px;
    }
    .img {
      margin-right: 5px;
      width: 20px;
      height: 20px;
      box-sizing: border-box;

    }
    .name {
      font-size: 12px;
    }
  }
}