.ProjectListHeader {
  background-color: #161A24;
  >.wrapper {
    width: 1200px;
    margin: auto;
    >.content {
      >.header {
        height: 20px;
        margin: 20px 0px;
        margin-left: auto;
        width: fit-content;
        >.breadcrumb {
          color: #999;
          width: inherit;
        }
      }
      >.body {
        display: flex;
        flex-flow: row;
        margin-bottom: 20px;
        >.page-title {
          color: #eee;
          font-size: 30px;
          width: fit-content;
          height: 40px;
          font-family: Roboto;
        }
        >.project-tabs {
          margin-left: auto;
          >.tabs {
            width: 618px;
            >.tab {
              .icon-wrapper {
                height: 100%;
                margin-right: 2px
              }
              .tab-icon {
                height: inherit;
                color: #999;
                display: flex;
                align-items: center;
                width: 18px !important;
                height: 18px !important;
              }
              >.tab-name {

              }
              &.active, &:hover {
                .tab-icon {
                  color: #232732;
                }
              }
            }
          }
        }
      }
    }
  }
}