.tos-page {
  display: flex;
  min-height: calc(100vh - 280px);
  padding-bottom: 100px;
  background-color: #1A1E28;
  flex-direction: column;
  >.header {
    width: 100%;
    height: 120px;
    background-color: #161A24;
    display: flex;
    justify-content: center;
    .content {
      display: flex;
      width: 1200px;
      justify-content: center;
      .switchTabs {
        display: flex;
        background-color: #383B45;
        height: 36px;
        border-radius: 6px;
        padding: 2px;
        align-items: center;
        text-align: center;
        width: 266px;
        margin-top: 60px;

        .switchButton {
          font-size: 12px;
          line-height: 128.7%;
          height: 36px;
          background-color: #383B45;
          color: #CCCCCC;
          padding: 0px;
          font-family: "Noto Sans KR";
          width: 130px;

          &:hover {
            background-color: #60626a;
            color: #eee;
          }

          &.enable {
            background-color: #EEEEEE;
            color: #232732;
          }
        }
      }
    }
  }
  >.body {
    box-sizing: border-box;
    padding-top: 30px;
    background-color: #1A1E28;
    justify-content: center;
    display: flex;
    >.content {
      display: flex;
      width: 1200px;
      flex-direction: column;
      >.title {
        font-size: 30px;
        color: #EEEEEE;
        height: 40px;
        margin-top: 30px;
      }
      >.line {
        border-bottom: 1px solid #EEEEEE;
        margin-top: 20px;
        height: 0px;
        width: inherit;
      }
      >.tos-list {
        width: inherit;
        display: flex;
        flex-direction: column;
        width: fit-content;
        margin-top: 50px;

        >.list-content {
          width: inherit;
          display: flex;
          flex-direction: column;
          width: fit-content;
          >.title {
            font-size: 30px;
            color: #CCCCCC;
          }
          >.sub-title {
            font-size: 18px;
            color: #EEEEEE;
          }
          >.content {
            color: #CCCCCC;
            font-size: 14px;

            >.indent {
              margin-left: 25px;
            }
          }
          ul {
            margin: 0;
          }
          ul.dashed {
            list-style-type: none;
          }
          ul.dashed > li {
            text-indent: -5px;
          }
          ul.dashed > li:before {
            content: "- ";
            text-indent: -5px;
          }
        }

      }
    }
  }
}