.Pagination {
  display: flex;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  .router-link {
    text-decoration: none;
    display: flex;
    .icon {
      fill: inherit;
    }
  }
  .round-icon {
    min-width: 30px;
    min-height: 30px;
    width: 30px;
    height: 30px;
    box-shadow: none;
    background-color: #232732;
    font-size: 14px;
    color: #ccc;
    margin: 0px 1px;

    &.active {
      background-color: #60626A;
      color: #eeeeee;
    }
    &:hover {
      background-color: #273945 !important;
      color: #cccccc !important;
    }

    &.arrow{
      background-color: #232732;
      margin: 0px 10px;
      &.enabled{
        .icon {
          color: #ccc;
        }        
      }
    }

    &.double-arrow{
      background-color: #232732;
      margin: 0px 0px;
      stroke: #555555;
      
      &.enabled{
        .icon {
          stroke: #cccccc;
          stroke-width: 0px;
          color: #ccc;
        }        
      }
    }

    .icon {
      width: 18px;
      height: 18px;
      color: #555;
    }
  }

  .ellipsis {
    width: 12px;
    height: 18px;
    font-size: 14px;
    color: #cccccc;
    margin-left: 2px;
    margin-right: 2px;
  }
}