.buildit-template {
  .tabs {
    &.horizontal {
      &.tabs-small {
        height: 30px;
      }
      height: 40px;
      box-sizing: border-box;
      border-radius: 6px;
      display: flex;
      align-items: center;
      padding: 1px;
      font-size: 14px;
      .tab {
        height: 36px;
        border-radius: 6px;
        color: #ccc;
        font-family: 'Noto Sans KR';
        padding: 0px;
        align-items: center;
        font-size: inherit;
        flex: 1;
        font-family: inherit;
        margin-left: 1px;
        margin-right: 1px;
      }
    }
    // 다시만들어야함 2020 03 24
    &.vertical {
      box-sizing: border-box;
      border-radius: 6px;
      width: 40px;
      display: flex;
      flex-flow: column;
      align-items: center;
      padding: 1px;
      .tab {
        flex: 1;
        font-family: inherit;
        min-width: 36px;
        width: 36px;
        color: #ccc;
        font-family: 'Noto Sans KR';
        padding: 0px;
        align-items: center;
        font-size: inherit;
        margin-top: 1px;
        margin-bottom: 1px;
      }
    }
    &.bg-navy {
      background-color: #383b45;
    }
  }
  .bg-navy {
    &.tab-primary {
      min-width: 40px;
      background-color: #383b45;
      color: #ccc;
      &:hover, &.active {
        background-color: #eee;
        color: #232732;
      }
      &.tab-small {
        min-width: 30px; 
        font-size: 12px;
        height: 26px;
      }
    }
    &.tab-secondary {
      height: 40px;
      min-width: 40px;
      box-sizing: border-box;
      font-family: inherit;
      // border: solid 1px #333333;
      background-color: #232732;
      color: #ccc;
      &:hover, &.active {
        background-color: #60626a;
        color: #eee;
      }
      &.tab-small {
        height: 30px;
        min-width: 28px;
      }
      &.tab-disabled {
        color: #555555; 
      }
    }
    &.tab-fix-padding {
      padding-left: 15px;
      padding-right: 15px;
    }

    &.first-child {
      margin-left: 0px;
    }
    &.last-child {
      margin-right: 0px;
    }

  }

  .btn {
    min-width: 40px;
    height: 40px;
    border-radius: 6px;
    font-size: 14px;
    box-sizing: border-box;
    font-family: 'Noto Sans KR';
    display: flex;
    flex-flow: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    &.bg-navy {
      /* type */
      &.btn-primary {
        font-family: inherit;
        background-color: #383b45;
        color: #ccc;
        min-width: 40px;
        &:hover, &.active {
          // background-color: #60626a;
          background-color: #273945;
          color: #eee;
        }
        &.disabled {
          background-color: #333333;
          color: #555555;
          cursor: default;
          pointer-events: none;
        }
      }
      &.btn-secondary {
        min-width: 40px;
        font-family: inherit;
        background-color: #232732;
        color: #ccc;
        &:hover, &.active {
          border: none;
          background-color: #60626a;
          color: #eee;
        }
        &.disabled {
          border: solid 2px #333333;
          background-color: #232732;
          color: #555555;
          cursor: default;
        }
      }
      &.btn-cancel {
        min-width: 40px;
        font-family: inherit;
        background-color: #232732;
        color: #999;
        &:hover, &.active {
          border: none;
          background-color: #60626a;
        }
        &.disabled {
          border: solid 1px #333;
          background-color: #232732;
          color: #555;
        }
      }
      &.btn-run {
        font-family: inherit;
        // background-image: linear-gradient(to bottom right, #48ddf2, #95e4b3);
        background-image: linear-gradient(to right, #48ddf2, #95e4b3);
        color: #232732;
        font-weight: bold;
        transition: background-image 1s;
        &.btn-small {
          height: 30px;
          font-size: 12px;
          font-weight: normal;
        }
        &:hover {
          background-image: linear-gradient(to right, #95e4b3, #48ddf2);
          transition: background-image 1s; 
        }
        &.active {
    
        }
        &.disabled {
          background-image: none;
          background-color: #333;
          color: #555;
        }
      }

      &.btn-extra {
        font-family: inherit;
        background-color: #232732;
        border: 1px solid #48ddf2;
        color: #48ddf2;
      }
      /* size */
      &.btn-small {
        height: 30px;
        font-size: 12px;
      }
    }
    
    &.bg-white {
      min-width: 0px;
      height: 40px;
      /* type */
      &.btn-primary {
        font-family: inherit;
        background-color: #232732;
        color: #ccc;
        min-width: 40px;
        &:hover, &.active {
          color: #eee;
        }
        &.disabled {
          background-color: #ccc;
          color: #999;
          cursor: default;
          pointer-events: none;
        }
      }
      &.btn-secondary {
        &:hover {
          
        }
        &.active {
    
        }
        &.disabled {
          
        }
      }
      &.btn-cancel {
        &:hover {
          
        }
        &.active {
    
        }
        &.disabled {
          
        }
      }
      &.btn-run {
        &:hover {
          
        }
        &.active {
    
        }
        &.disabled {
          
        }
      }
      /* size */
      &.btn-small {
        height: 30px;
      }
    }
  }

  .checkbox {
    color: #cccccc;
    padding: 0px;
    height: 100%;
    svg {
      width: 20px;
      height: 20px;
    }
    input {
      width: 20px;
      height: 20px;
    }
    &.checked {
      color: #95e4b3;
    }
  }
  
  
  .font {
    &.font-primary {
      color: #ccc;
    }
    &.font-secondary {
      color: #999;
    }
    &.font-emphasis {
      color: #eee;
    }
    &.font-special {
      color: #48ddf2;
    }
    &.font-error {
      color: #e81c1c;
    }
    &.font-noto {
      font-family: 'Noto Sans KR';
    }
    &.font-roboto {
      font-family: 'Roboto';
    }
    &.font-12px {
      font-size: 12px;
    }
    &.font-14px {
      font-size: 14px;
    }
    &.font-16px {
      font-size: 16px;
    }
    &.font-18px {
      font-size: 18px;
    }
  }

  .bgc-navy {
    background-color: #232732;
  }

  .bgc-white {
    background-color: #fff;
  }
  
  .icon {
    width: 20px !important;
    height: 20px !important;
    color: #ccc;
    &.active {
      color: #95E4B3;
    }
  }
  
  .m-l-a {
    margin-left: auto;
  }
  .m-r-a {
    margin-left: auto;
  }
  .m-a {
    margin: auto;
  }
  
  .m-l-sm {
    margin-left: 5px;
  }
  .m-l-md {
    margin-left: 10px;
  }
  .m-l-lg {
    margin-left: 15px;
  }
  .m-l-xl {
    margin-left: 20px;
  }
  
  .m-r-sm {
    margin-right: 5px;
  }
  .m-r-md {
    margin-right: 10px;
  }
  .m-r-lg {
    margin-right: 15px;
  }
  .m-r-xl {
    margin-right: 20px;
  }

  .m-t-sm {
    margin-top: 5px;
  }
  .m-t-md {
    margin-top: 10px;
  }
  .m-t-lg {
    margin-top: 15px;
  }
  .m-t-xl {
    margin-top: 20px;
  }

  .m-b-sm {
    margin-bottom: 5px;
  }
  .m-b-md {
    margin-bottom: 10px;
  }
  .m-b-lg {
    margin-bottom: 15px;
  }
  .m-b-xl {
    margin-bottom: 20px;
  }

  .t-a-c {
    text-align: center;
  }
  .t-a-l {
    text-align: left;
  }
  .t-a-r {
    text-align: right;
  }

  .visibility-hidden {
    visibility: hidden;
  }
  .display-none {
    display: none;
  }

  .router-link {
    text-decoration: none;
    color: inherit;
    display: flex;
    align-items: center;
    flex-flow: row;
  }

  .d-flex {
    display: flex;
  }
  .d-block {
    display: block;
  }
  .d-inline {
    display: inline;
  }
  .p-e-n {
    pointer-events: none;
  }
}
