.BuilditSelect {
  display: inline-flex;
  flex-flow: column;
  width: 100%;
  position: relative;
  background-color: #0C111B;
  // SearchPlace 열려진상태

  &.default {
    color: #cccccc;
  }

  &.table {
    background-color: #161A24 !important;
    height: 52px !important;
  }
  &.select {
    * {
      cursor: pointer;
    }
    >.select-header {
      background-color: inherit;
      box-sizing: border-box;
      width: inherit;
      color: inherit;
      position: relative;
      border-bottom: 1px solid #0c111b;
      display: flex;
      flex-flow: row;
      align-items: center;
      min-height: 40px;
      height: inherit;

      &.table {
        border-bottom: 1px solid #555555;
      }
      &.card-top {
        border-bottom: 1px solid #161A24;
      }

      >.select-icon {
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 100%;
        padding-right: 5px;
        box-sizing: border-box;
        >.icon {
          width: 20px;
          height: 20px;
        }
      }
      
      >.custom-placeholder {
        width: 100%; 
        height: 100%; 
        padding-Left: 10px; 
        align-Items: center;
        display: flex;
      }

      &.hover-disable {
        &.focus, &:focus, &:hover, &.opened {
          border-bottom: 0px solid #161A24 !important;
        }
      }
    
      &.focus, &:focus, &:hover, &.opened {
        border-bottom: 1px solid #ccc;
        transition: border-bottom-color 0.5s linear;
        background-color: #0C111B;
      }
    }
    >.bottom-header {
      background-color: inherit;
      box-sizing: border-box;
      width: inherit;
      color: #ccc;
      position: relative;
      border-bottom: 1px solid #0c111b;
      display: flex;
      flex-flow: column;
      align-items: center;
      min-height: 40px;
      height: inherit;

      &.table {
        border-bottom: 1px solid #555555;
      }
      &.card-top {
        border-bottom: 1px solid #161A24;
      }

      >.custom-placeholder {
        height: 50%; 
        margin-left: auto;
        margin-right: auto;
        padding-top: 10px;
        display: flex;
      }

      >.select-icon {
        display: flex;
        flex-flow: row;
        height: 50%;
        margin-left: auto;
        margin-right: auto;
        box-sizing: border-box;
        padding-top: 2px;
        >.icon {
          width: 10px !important;
          height: 10px !important;
        }
      }

      &.hover-disable {
        &.focus, &:focus, &:hover, &.opened {
          border-bottom: 0px solid #161A24 !important;
        }
      }
    
      &.focus, &:focus, &:hover, &.opened {
        border-bottom: 1px solid #ccc;
        transition: border-bottom-color 0.5s linear;
        background-color: #0C111B;
      }
    }
  }
    >.select-wrapper {
      display: flex;
      flex-flow: column;
      background-color: #0C111B;
      position: absolute;
      margin-top: 40px;
      z-index: 1;
      border: 1px solid #555555;
      border-top: none;
      box-sizing: border-box;
      width: inherit;
      max-height: 300px;
      overflow: auto;

      &.select-table {
        margin-top: 52px !important;
      }

      >.option {
        display: flex;
        box-sizing: border-box;
        padding: 10px;
        align-items: center;
        height: 40px;
        color: #ccc;
        background-color: #0C111B;
        font-size: 14px;
        cursor: pointer;
        &.option-table {
          padding: 0px !important;
          justify-content: center !important;
          height: 50px !important;
        }
        &:first-child {
          border-top: 0px;
        }
        &:hover {
          background-color: #273945;
        }

        &.disabled {
          color: #555555;
          cursor: default;
          background-color: #0C111B;
          pointer-events: none;
        }
      }
    }
}