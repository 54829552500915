.c3-axis-y text {
  fill: #999;
  font-size: 10px;
  font-family: 'Roboto', 'Noto Sans KR';
}


.c3-legend-item text {
  font-size: 12px;
  fill: #999;
  font-family: 'Roboto', 'Noto Sans KR';
}

.c3-axis-x text {
  font-size: 11px;
  fill:#ccc;
  font-family: 'Roboto', 'Noto Sans KR';
  
}

.c3-axis path {
  stroke: #999;
}

.c3-axis-x {
  line {
    stroke: none;
  }
}

.c3-axis-y {
  line {
    stroke: #555;
  }
}

.one {
  .c3-axis path,
  .c3-axis line,
  .c3-axis-y text,
  .c3-legend-item {
    visibility: hidden !important;
  }

  
}

.multi {
 
}

.C3ChartWrapper {
  
  .C3Chart {
    .c3-shape {
      stroke: #232732;
    }
  }
  .C3Chart-legend {
    justify-content: center;
    display: flex;
    flex-flow: column;
    >.legend-item {
      display: flex;
      flex-flow: row;
      align-items: center;
      margin-bottom: 10px;
      align-items: center;
      color: #999;
      width: 296px;
      &.focused {
        >.legend-id, >.proportion {
          color: #ccc;
        }
      }
      cursor: pointer;
      >.symbol {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
      >.legend-id {
        margin-left: 10px;
        width: 170px;
        height: 20px;
        color: inherit;
        display: flex;
        flex-flow: row;
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      >.proportion {
        display: flex;
        flex-flow: row;
        align-items: center;
        height: 20px;
        width: 40px;
        margin-left: auto;
      }
    }
  }
  &.bottom {
    display: flex;
    flex-flow: column;
    
  }
  &.right {
    display: flex;
    flex-flow: row;
    >.C3Chart-legend {
      flex-flow: column;
      >.legend-item {
        margin-left: 35px;
      }
    }
  }
}