.ProjectTypeTag {
  display: flex;
  align-items: center;
  justify-content: center;
  &.WHITE {
    &.AI {
      width: 60px;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      border: solid 1px #5e9eff;
      color: #5e9eff;
      font-size: 10px;
    }
    &.CAL {
      width: 60px;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      border: solid 1px #c25eff;
      color: #c25eff;
      font-size: 10px;
    }
    &.DESIGNER {
      width: 60px;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      border: solid 1px #ff8b1f;
      color: #ff8b1f;
      font-size: 10px;
    }
    &.SITE_PLAN {
      width: 60px;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      border: solid 1px #95e4b3;
      color: #95e4b3;
      font-size: 10px;
    }
    &.UNDEFINED {
      padding: 0px;
      border-radius: 6px;
      border: solid 1px #999999;
      color: #999999;
      font-size: 10px;
    }

    &.NOCONFIG {
      padding: 0px;
      border-radius: 6px;
      border: solid 1px #999999;
      color: #999999;
      font-size: 10px;
    }
  }
  &.DARK {
    border: 1px solid #ccc;
    color: #ccc;
    &.AI {
      width: 60px;
      box-sizing: border-box;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      // border: solid 1px #5e9eff;
      // color: #5e9eff;
      font-size: 10px;
    }
    &.CAL {
      width: 60px;
      box-sizing: border-box;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      // border: solid 1px #c25eff;
      // color: #c25eff;
      font-size: 10px;
    }
    &.DESIGNER {
      width: 60px;
      box-sizing: border-box;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      // border: solid 1px #ff8b1f;
      // color: #ff8b1f;
      font-size: 10px;
    }
    &.SITE_PLAN {
      width: 60px;
      box-sizing: border-box;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      // border: solid 1px #95e4b3;
      // color: #95e4b3;
      font-size: 10px;
    }
    &.UNDEFINED {
      width: 60px;
      box-sizing: border-box;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      // border: solid 1px #999999;
      // color: #999999;
      font-size: 10px;
    }

    &.NOCONFIG {
      width: 60px;
      box-sizing: border-box;
      height: 16px;
      padding: 0px;
      border-radius: 6px;
      // border: solid 1px #999999;
      // color: #999999;
      font-size: 10px;
    }
  }
}