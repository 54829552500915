.DownloadButton {
  justify-content: flex-start !important;
  padding: 0px 10px !important;
  font-weight: bold !important;
  .icon {
    margin-left: auto;
  }
  .progress-icon {
    width: 15px !important;
    height: 15px !important;
    padding: 2.5px;
  }
  .download-icon {

  }
}