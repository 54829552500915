.BuildingLaws {
  >.header {
    >.title {

    }
  }
  >.body {
    margin-top: 10px;
    display: flex;
    flex-flow: row;
    width: 586px;
    height: 100%;
    font-family: "Noto Sans KR";
    >.tabs {
      width: 178px;
      height: 232px;
      .tab {
        width: 174px;
      }
    }
    >.content {
      color: #ccc;
      font-size: 12px;
      margin-left: 20px;
      height: max-content;
      height: 390px;
      >.info {
        >.img {
          width: 390px;
          height: 306px;
          margin-bottom: 10px;
        }

        display: flex;
        flex-flow: column;
        height: 350px;
      }
    }
  }
}