.ProjectListBody {
  background-color: #1A1E28;
  padding-top: 30px;
  .wrapper {
    width: 1200px;
    margin: auto;
    >.total-count {
    }
    >.header {
      display: flex;
      flex-flow: row;
      margin-top: 10px;
      >.search-wrap {
        display: flex;
        flex-flow: row;
        >.search-field {
          color: #cccccc;
          box-sizing: border-box;
          width: 120px;
          margin-right: 10px;
          height: 40px;
        }
        >.search-text {
          color: #cccccc;
          box-sizing: border-box;
          width: 260px;
          height: 40px;
          .icon-btn {
            padding: 0px;
            .icon {
              color: #ccc;
            }
            .remove-icon {
              width: 14px !important;
              height: 14px !important;
              margin-right: 10px;
            }
          }
        }
        >.search-info {
          display: flex;
          margin-left: 10px;
          align-items: center;
          width: 534px;
          overflow-wrap: break-word;
        }
      }

      >.table-wrap {
        margin-left: auto;
        display: flex;
        flex-flow: row;
        >.table-tabs {
          >.tabs {
            width: 116px;
            >.tab {
              .icon-wrapper {
                height: 100%;
                margin-right: 2px
              }
              .tab-icon {
                height: inherit;
                color: #999;
                display: flex;
                align-items: center;
                width: 18px !important;
                height: 18px !important;
              }
              >.tab-name {
  
              }
              &.active, &:hover {
                .tab-icon {
                  color: #232732;
                }
              }
            }
          }
        }
  
        >.view-wrap {
          margin-left: 11px;
          color: #cccccc;
          >.view-tabs {
            width: 116px;
          }
          >.view-num {
            width: 120px;
          }
        }

      }
    }
    >.body {
      margin-top: 10px;
      >.buttons {
        display: flex;
        margin-top: 20px;
        >.icon {
          width: 20px;
          height: 20px;
        }
      }
      >.project-pagination {
        margin-top: 20px;
      }
    }
  }
}

.delete-modal {
  width: 400px;

  >.description {
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    font-family: "Noto Sans KR";
  }

  >.content-list {
    max-height: 300px;
    overflow: hidden;
    overflow-y: scroll;
    height: auto;
    font-weight: normal;
    .content-row {
      display: flex;
      flex-direction: row;
      width: 360px;
      height: 30px;
      align-items: center;
      font-size: 14px;
      font-family: "Roboto";
      
      .icon {
        width: 15px !important;
        height: 15px !important;
      }
      >.project-name {
        width: 330px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 12px;
      }
      >.dicount-name {
        width: 310px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        margin-left: 0px;
      }
    }
  }
}

