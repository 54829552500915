.ProjectFieldInput {
  // position: relative;
  >.header {
    margin-bottom: 5px;
    display: flex;
    flex-flow: row;
    height: 20px;
    >.title {
      color: #999;
      font-size: 12px;
      display: inline-block;
      line-height: 16px;
    }
    >.unit-select {
      margin-left: auto;
      >.unit {
        width: 80px;
        height: 20px;
      }
    }
  }
  >.content {
    color: #ccc;
    .diffrent-default {
      color: #95E4B3;
    }
    >.default-value {
      background-color: rgba($color: #48DDF2, $alpha: 0.3);
      color: #EEEEEE;
      width: inherit;
      align-items: center;
      display: flex;
      font-weight: 400;
      
      &.select {
        height: 37px;
        font-size: 14px;
        padding-left: 10px;
        padding-top: 3px;
        line-height: 16px;
      }
      &.input {
        height: 39px;
        justify-content: flex-end;
        font-size: 16px;
        line-height: 19px;
        padding-bottom: 1px;
        >.value {
          &.no-unit {
            margin-right: 10px;
          } 
        }
        >.unit {
          margin-left: 10px;
          margin-right: 20px;
          font-size: 14px;
          font-weight: 100;
        }
      }
    }
    >.BuilditInput {
      height: 40px;      
      >.input-wrapper {
        >.start-adornment {
          >.icon-btn {
            padding: 0px;
            .icon {
              color: #ccc;
              width: 20px;
              height: 20px;
            }
            &.dec-btn {
              margin-right: 5px;
            }
          }
        }
      }
      .unit {
        line-height: 19px;
        color: #cccccc;
      }
    }
  }
}