.PointUsageListContent {
  position: relative;
  width: 1200px;
  margin: 0 auto;
  overflow: -webkit-paged-x;
  min-height: calc(100vh - 180px);

  .PointUsageInfo {
    display: flex;
    align-items: center;
    padding-bottom: 5px;
    border-bottom: solid;
    border-width: 2px;
    font-size: 22px;

    .icon {
      margin: 0 5px;
    }
    .bold {
      font-weight: bold;
    }
  }
  
  .header {
    display: flex;
    margin-top: 32px;
    justify-content: space-between;
    .description {
      display: flex;
      >.tooltip {
        align-items: initial;
      }
      .icon {
        margin-left: 6.5px;
        margin-right: 5px;
        object {
          width: 15px;
          height: 15px;
        }
      }

      >div {
        line-height: 26px;
      }
    }
  
    .switchTabs {
      // position: relative;
      // right: 10px;
      background-color: #383B45;
      width: 214px;
      height: 26px;
      justify-content: space-evenly;
      padding: 2px;
  
      .switchButton {
        width: 70px;
        height: 26px;
        font-size: 12px;
        padding: 0px;
        min-width: auto;
        color: #EEEEEE;
        font-family: Noto Sans KR;

        &:hover {
          background-color: #60626a;
          color: #eee;
        }
  
        &.enable {
          background-color: #EEEEEE;
          color: #232732;
        }
      }
    }
  }
  

  .table {
    margin-top: 20px;
    font-size: 14px;
    border: 0 0;
    text-align: left;

    table {
      border-spacing: 0px;
      word-break: break-all;
      width: 1200px;

      thead {
        display: flex;
        flex-direction: row;
      }

      tr {
        display: flex;
        flex-direction: row;
      }

      th {
        color: #cccccc;
        background: #161A24 !important;
        height: 45px;
        font-size: 14px;
        font-weight: normal;
        padding-bottom: 1px;
        border-bottom:1px solid #555555;
        font-family: Noto Sans KR;
        display: flex;
        flex-direction: row;
        padding: 0;
        
      }

      td {
        height: 50px;
        color: #cccccc;
        background-color: #232732;
        padding-bottom: 1px;
        border-bottom:1px solid #333333;
        font-family: "Roboto";
        font-size: 14px;
        align-items: center;
        display: flex;
        flex-direction: row;
        padding: 0;


        &.rightAlign {
          text-align: right;
        }

        
      }

      .purchase {
        &.serialNum {
          width: 120px;
        }
        &.date {
          width: 170px;
        }
        &.purchaseState {
          width: 80px;
        }
        &.purchaseDetail {
          width: 440px;
        }
        &.price {
          width: 195px;
        }
        &.remarks {
          width: 195px;
        }
      }

      .point {
        display: flex;
        &.serialNum {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.date {
          width: 170px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.state {
          width: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.detail {
          width: 170px;
        }
        &.BIPoint {
          width: 140px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.project-number {
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
          &.hoverable {
            &:hover {
              background-color: #273945;
            }
          }
          >.content-wrapper {
            display: flex;
            flex-direction: row;
            align-items: center;
            >.content {
              overflow: hidden;
              text-overflow: ellipsis;
            }

            >.icon {
              margin-left: 5px;
              width: 18px;
              height: 18px;
            }

          }
        }
        &.project-type {
          width: 100px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.servicePoint {
          width: 135px;
        }
        &.totalPoint {
          width: 195px;
        }
        &.remarks {
          padding-left: 10px;
          display: flex;
          align-items: center;
          width: 520px;
          flex-direction: row;
          .project-number {
            display: flex;
            align-items: center;
            >.result-link {
              text-decoration: none;
              color: #cccccc;
            }
            &.disabled {
              pointer-events: none !important;
            }
          }
          .project-type {
            display: flex;
            align-items: center;
            margin-left: 5px;
            >.type-title {
              display: flex;
              align-items: center;
            }
            >.type-tag {
              display: flex;
              align-items: center;
              margin-left: 5px;
            }
          }
        }
      }
    }

    .lastLine {
      width: 1200px;
      height: 1px;
      background-color: #eee;
    }

    .pagination {
      margin-top: 50px;
      margin-bottom: 100px;
    }

    .noItemText {
      display: flex;
      width: 1200px;
      height: 500px;
      position: relative;
      background-color: #232732;
      justify-content: center;
      align-items: center;
      text-align: center;
      margin-bottom: 100px;
    }

    .infoText {
      margin-top: 50px;
      width: 100%;
      color: #999;
      text-align: center;
    }
  }
}
