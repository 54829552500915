.ProjectFormValue-Header {
  background-color: rgba($color: #161A24, $alpha: 1.0);
  width: 100%;
  position: sticky;
  top: 0px;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  
  >.description {
    margin-top: 10px;
    margin-left: 30px;
    width: 260px;
    font-size: 12px;
    line-height: 14px;
    color: #95E4B3;
  }

  >.show-button {
    margin-top: 10px;
    margin-right: auto;
    width: 100px;
    height: 30px;
    background-color: #161A24;
    color: #cccccc;
    align-items: center;
    font-size: 12px;
    line-height: 14px;
    
    &:hover {
      background-color: #273945;
    }
    .icon {
      margin-left: 3px;
      width: 18px !important;
      height: 18px !important;
      color: #999999;
      &.active {
        color: #48ddf2;
      }
    }
  }
}

.ProjectFormBasic {
  padding-top: 30px;
  padding-bottom: 30px;
  margin-left: 30px;
  margin-right: 30px;
  width: 360px;
  .more-fields {
    display: flex;
    flex-flow: row wrap;
  }

  .project-field {
    margin-bottom: 30px;
    display: flex;
    flex-flow: column;
    .width-full {
      width: 360px;
    }
    .width-half {
      width: 175px;
    }
    >.title {
      color: #999999;
      font-size: 12px;
      margin-bottom: 10px;
    }
    >.content {
      &.project-address {
        width: 360px;
        height: 40px;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #eeeeee;
      }

      &.description {
        margin-top: 10px;
        width: 360px;
        height: 36px;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #cccccc;
      }

      &.detail-description {
        width: 360px;
        height: 18px;
        display: flex;
        align-items: center;
        font-size: 12px;
        color: #48DDF2;
        margin-top: 5px;
      }

      &.table {
        margin-top: 10px;
        margin-bottom: 10px;

        table {
          width: 280px;

          tr {
            width: 100%;
            height: 30px;
            display: flex;
            flex-direction: row;
            color: #cccccc;
            font-size: 14px;
            font-family: "Roboto";
            th {
              display: flex;
              width: 90px;
              align-items: center;
              font-weight: 400;
              height: 29px;
              justify-content: center;
              border-bottom: solid;
              border-color: #333333;
              border-width: 1px;

              &.first {
                width: 99px !important;
                border-right: solid;
                border-color: #333333;
                border-width: 1px;
              }
            }

            td {
              display: flex;
              width: 90px;
              align-items: center;
              font-weight: 400;
              justify-content: center;
              height: inherit;

              &.first {
                width: 90px !important;
                border-right: solid;
                border-color: #333333;
                border-width: 1px;
                justify-content: flex-start;
                padding-left: 10px;
              }
            }
          }
        }
      }

      .btn-group {
        .btn {
          background-color: #232732;
          padding-left: 15px;
          padding-right: 15px;
          border: 1px solid #555555;
          color: #eeeeee;
          margin-right: 5px;
          margin-bottom: 5px;
          font-size: 14px;

          &:hover, &.active {
            background-color: #60626a;
            font-weight: bold;
          }
        }
                
        &.project-use-district {
          
        }
      }
    }

    &.project-address {
      >.title {
        display: flex;
        align-items: center;
        >.jigu-info {
          cursor: pointer;
          -webkit-user-select: none;
        }
      }
    }
  }

  .required-icon {
    width: 3px;
    height: 3px;
  }

  .more-btn {
    margin: auto;
    width: 100px;
    margin-bottom: 30px;
  }

  .use_district_regulation {
    >.btn {
      width: 140px;
      font-size: 12px;
      margin-left: auto;
      display: flex;
      align-items: center;
      margin-top: -10px;
      .icon {
        color: #48ddf2;
      }
    }
    >.regulation-wrap {
      margin-top: 20px;
      >.regulation {
        &.land {

        }
        &.etc {
          
        }
        >.type {
          &.include {

          }
          &.interference {
            
          }
          &.boundary {
            
          }
        }
      }
    }
  }
}