.Switch {
  display: inline-flex;
  align-items: center;
  flex-flow: row;
  >.rc-switch {
    background-color: #555;
    border-color: #555;
    width: 30px;
    height: 15px;
    &.rc-switch-checked {
      border: 1px solid #48DDF2;
      background-color: #48DDF2;
      &:after {
        left: 15px;
      }
    }
    &:after {
      width: 11px;
      height: 11px;
    }
    &:hover {
      &:after {
        transform: none;
        -webkit-transform: none;
        animation-name: none;
        -webkit-animation-name: none;

      }
    }
  }
  >.name {
    margin-left: 5px;
    color: #eee;
    font-size: 12px;
  }
}