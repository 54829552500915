.ProjectFormBuilding {
  display: flex;
  flex-flow: row;
  height: 100%;
  .building-types {
    >ul {
      margin: 0px;
      padding: 0px;
    }
    height: 100%;
    display: flex;
    flex-flow: column;
    >.type {
      &.buildit-type {
        border-right: 1px solid #333;
        border-bottom: 1px solid #333;
      }

      &.my-type {
        border-right: 1px solid #333;
        border-top: 1px solid #333;
      }

      &.active {
        border: 0px;
        background-color: #161A24;
        >.title {
          color: #95e4b3;
        }
      }
      >.title {
        color: #ccc;
        font-size: 12px;
        margin-bottom: 10px;
        margin-top: 20px;
      }
      flex: 1 1 100%;
      box-sizing: border-box;
      width: 120px;
      background-color: #1a1e28;
      align-items: center;
      display: flex;
      flex-flow: column;
      margin: auto;
    }
  }
  .building-items-wrapper {
    overflow: hidden;
    .items-header {
      .info {
        color: #666;
      }
      padding-left: 30px;
      margin-top: 30px;
      &.BUILDIT_TYPE {
        height: 50px;
      }
      &.MY_TYPE {
        height: 120px;
        
      }
      .my-building-type-wrap {
        width: 224px;
        // align-items: center;
        margin-top: 10px;
        .title {
          display: flex;
          align-items: center;
        }
        .button-wrapper {
          width: 224px;
          margin-top: 10px;
          height: 30px;
          display: flex;
          >.upload-btn {
            width: 179px;
            height: 30px;
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            padding-left: 10px;
      
            .icon {
              margin-left: 2px;
              margin-right: auto;
              width: 14px !important;
              height: 14px !important;
              color: inherit;
            }
          }
    
          >.refresh-btn {
            margin-left: 5px;
            width: 40px;
            height: 30px;
      
            .icon {
              margin-left: 2px;
              width: 20px !important;
              height: 20px !important;
              color: inherit;
            }
          }
        }
        .search-field {
          margin-top: 10px;
          width: 224px;
          .icon-btn {
            padding: 0px;
            .icon {
              
            }
            .remove-icon {
              width: 14px !important;
              height: 14px !important;
              margin-right: 10px;
            }
          }
        }
      }
    }
    .building-items {
      box-sizing: border-box;
      width: 300px;
      overflow-y: auto;
      padding-left: 30px;
      padding-top: 10px;
      &.BUILDIT_TYPE {
        height: calc(100% - 60px);
      }
      &.MY_TYPE {
        height: calc(100% - 130px);
      }
      >.title {
        margin-top: 10px;
        height: 30px;
        display: flex;
        align-items: center;
      }
      >.info {
        height: 20px;
        display: flex;
        align-items: center;
        padding-bottom: 20px;
        font-size: 12px;
        color: #ccc;
      }
      
      
      
      
      >.buffer {
        height: calc(100% - 70px - 218px);
      }
    }
  }

  .building-types-btn {
    box-sizing: border-box;
    width: 50px;
    height: 50px;
    background-color: #232732;
    min-width: 0px;
    margin-bottom: 10px;
    padding: 0px;
    color: #ccc;
    font-size: 12px;

    &:last-child {
      margin-bottom: 20px;
    }

    &:hover, &.active {
      background-color: #232732;     
      border: solid 1px #95e4b3;
      .active-img {
        display: block;
      }
      .default-img {
        display: none;
      }
    }
    .active-img {
      display: none;
    }
  }
  .building-drawer-open-btn {
    min-width: 0px;
    width: 50px;
    height: 50px;
    padding: 0px;
    color: #48ddf2;
    font-size: 12px;
    border: solid 1px #48ddf2;
    background-color: #383b45;
  }

}

.BuildingItems {
  margin-bottom: 30px;
  .title {
    padding-bottom: 15px;
  }
  >.houses-btn-wrap {
    margin-bottom: 10px;
    >.houses-btn {
      padding: 0px;
      margin-right: 2px;
      .houses-value {
        display: flex;
      }
      width: 28px;
      height: 30px;
      box-sizing: border-box;
    }
  }
  >.img-btn {
    position: relative;
    width: 224px;
    box-sizing: border-box;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    background-color: #232732;


    .building-types-icon {
      width: 50px;
      height: 50px;
      opacity: 0.8;
      position: absolute;
      display: flex;
      
      &.building-types-add {
        display: none;
      }
      &.building-types-check {
        color: #95e4b3;
      }
      &.building-types-sub {
        display: none;
      }
    }

    &:hover {
      background-color: #273945;
      .building-types-add {
        color: #48ddf2;
        display: flex;
      }
      .building-types-check {
        display: none;
      }
      .building-types-sub {
        color: #eeeeee;
        display: flex;
      }
    }
  }
}

.MyBuildingItems {
  background-color: #232732;
  margin-bottom: 30px;
  border-radius: 6px;
  width: 224px;
  &.empty {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #999;
    font-size: 12px;
    &:hover {
      background-color: #232732;
    }
  }
  &:hover {
    background-color: #273945;
  }
  >.header {
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 20px 10px 10px 10px;
    >.id {
      margin-right: 10px;
      color: #ccc;
      font-size: 14px;
    }
  }
  >.img {
    width: 224px;
    height: 188px;
    display: flex;
    justify-content: center;
    >.img-btn {
      position: relative;
      width: 224px;
      height: 188px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 6px;
      img {
        width: 188px;
        height: 188px;
      }
      .building-types-icon {
        width: 50px;
        height: 50px;
        opacity: 0.8;
        position: absolute;
        display: flex;
        
        &.building-types-add {
          display: none;
        }
        &.building-types-check {
          color: #95e4b3;
        }
        &.building-types-sub {
          display: none;
        }
      }
  
      &:hover {
        background-color: inherit;
        .building-types-add {
          color: #48ddf2;
          display: flex;
        }
        .building-types-check {
          display: none;
        }
        .building-types-sub {
          color: #eeeeee;
          display: flex;
        }
      }
      .building-types-add {
        display: none;
      }
    }
  }
  >.info {
    display: flex;
    flex-flow: column;
    padding: 10px 10px 20px 10px;
    >.name {
      color: #ccc;
      font-size: 14px;
      margin-bottom: 10px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space:nowrap;
    }
    >.key-value {
      display: flex;
      align-items: center;
      font-size: 12px;
      height: 20px;
      >.key {
        color: #999;
      }
      >.value {
        color: #ccc; 
        margin-left: auto;
      }
    }
  }
}

