.ProjectResultCard {
  width: 224px;
  box-sizing: border-box;
  background-color: #232732;
  position: relative;
  border-radius: 6px;

  &.COMPARE {
    >.content {
      >.key-value {
        height: 40px;
        display: flex;
        align-items: center;
        >.value {
          text-align: center;
          margin: auto;
        }
      }
    }
    >.cad-img {
      position: relative;
      cursor: pointer;
      &:hover {
        .go-to-icon {
          position: absolute;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 60px;
          height: 40px;
          color: #eee;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  &.NORMAL {
    &:hover {
      background-color: #273945;
      cursor: pointer;
      >.cad-img {
        position: relative;
      }
      .go-to-icon {
        position: absolute;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 40px;
        color: #eee;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &.checked {
    
  }
  

  .go-to-icon {
    display: none;
  }


  

  >.header {
    display: flex;
    align-items: center;
    font-size: 14px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 16px 10px 16px;
    .checkbox {
      padding: 0px;
      input { // checkbox
        width: 40px;
        height: 40px;
        transform: translate(-25%, -25%);
      }
    }
  }
  >.cad-img {
    width: 224px;
    height: 224px;
    text-align: center;
    >img {
      width: 222px;
      height: 222px;
      background-color: #161A24;
    }
  }
  >.content {
    padding: 10px 0px 20px 0px;
    >.key-value {
      padding: 0px 16px;
      height: 20px;
      box-sizing: border-box;
      display: flex;
      flex-flow: row;
      align-items: flex-end;
      >.key {
        width: 100px;
        font-size: 12px;
        color: #999;
        display: flex;
        align-items: center;
      }
      >.value {
        font-size: 12px;
        color: #ccc;
        margin-left: auto;
        display: flex;
        align-items: center;
      }
    }
  }
}