.sitePageContent {
  position: relative;
  width: 1200px;
  min-height: calc(100vh - 180px);
  margin: 0 auto;
  overflow: -webkit-paged-x;
  background-color: #1A1E28;

  .siteInfo {
    position: relative;
    display: flex;
    height: 30px;
    margin-top: 32px;
    >.tooltip {
      align-items: initial;
    }
    >div {
      line-height: 26px;
    }
    .icon {
      margin-left: 6.5px;
      margin-right: 5px;
      object {
        width: 15px;
        height: 15px;
      }
    }
    .bold {
      font-weight: bold;
    }
  }

  > .info {
    position: relative;
    font-size: 18px;
    line-height: 26px;
    display: flex;
    align-items: center;
    color: #CCCCCC;

    div{
      display: inline;
    }

    .icon {
      margin-left: 6.5px;
      color: #EEEEEE
    }

    span{
      font-weight: bold;
    }
  }
  
  >.interactionLayer {
    position: relative;
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
    
    .search-wrapper {
      display: flex;
      .search-field-select {
        display: flex;
        width: 120px;
        height: 40px;
        margin-right: 10px;
      }
      .input-search {
        width: 259px;
        margin-right: 10px;
        height: 40px;

        .svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          margin-top: 5px;
        }
        .icon-btn {
          padding: 0px;
          .icon {
            
          }
          .remove-icon {
            width: 14px !important;
            height: 14px !important;
            margin-right: 10px;
          }
        }
      }
      .search-info {
        display: flex;
        width: 600px;
        align-items: center;
      }
    }

    .buttonLayer {  
      .callButton {
        width: 180px;
        height: 40px;
        align-items: center;
        display: flex;
  
        .buttonText {
          display: flex;
          line-height: 20px;
          width: 117px;
          height: 24px;
          text-align: center;
        }

        .img {
          display: flex;
          width: 15px;
          height: 15px;
          margin-left: 6.7px;
          .object {
            width: 15px;
            height: 15px;
          }
        }
      }
    }
  }

  >.file-layer {
    justify-content: space-between;
    display: flex;
    margin-top: 10px;
    background-color: #161A24;
    width: inherit;
    height: 40px;
    align-items: center;
    >.left-wrapper {
      display: flex;
      >.check-box {
          color: #999999;
          width: 20px;
          height: 20px;
          padding: 0px;
          margin-left: 10px;
          &.checked {
            color: #95e4b3;
          }
      }
    }

    >.right-wrapper {
      display: flex;  
      >.file-button {
        width: 170px;
        height: 40px;
        align-items: center;
        display: flex;
        justify-content: space-between;
  
        .buttonText {
          display: flex;
          line-height: 20px;
          font-size: 14px;
          font-weight: normal;
          width: fit-content;
          text-align: center;
          padding-left: 10px;
        }

        .icon {
          display: flex;
          width: 20px;
          height: 20px;
          margin-right: 10px;
          align-items: center;
        }

        &.not-first {
          margin-left: 5px;
        }
      }

      .delete-Button {
        width: 40px;
        height: 40px;
        margin-left: 5px;

        .icon {
          display: flex;
          width: 20px;
          height: 20px;
          align-items: center;
          color: #999999;
        }
      }
    }
  }

  .cardTable {
    position: relative;
    top: 30px;
    display: flex;
    flex-flow: wrap;
    justify-content: flex-start;

    .card {
      position: relative;
      width: 285px;
      height: fit-content;
      box-sizing: border-box;
      border-radius: 6px;
      background-color: #232732;
      display: flex;
      flex-flow: column;
      // background-color: red;
      margin-bottom: 30px;

      .header{
        display: flex;
        width: 285px;
        height: 20px;
        margin-top: 15px;
        margin-bottom: 15px;
        align-items: center;
        
        .titleCheck{
          display: flex;
          width: 20px;
          height: 20px;
          color: #999999;
          margin-left: 16px;

          &.checked {
            color: #95e4b3;
          }
        }

        .title{
          display: flex;
          font-weight: normal;
          line-height: 16px;
          margin-left: 10px;
        }
      }
      .over-img {
        position: absolute;
        z-index: 100;
        background-color: rgba($color: #000000, $alpha: 0.5);
        width: 285px;
        height: 285px;
        top: 50px;
        left: 0px;
        display: flex;
        flex-direction: column;

        .button {
          width: 40px;
          margin-left: auto;
          margin-right: auto;
          margin-top: 10px;

          &.start{
            margin-top: auto;
          }

          &.end {
            margin-bottom: auto;
          }
        }
      }
      .img {
        position: relative;
        width: 285px;
        height: 285px;
        background-color: #161A24;
        border-color: #232732;
      }

      .info {
        position: relative;
        margin-top: 10px;
        .nameTag {
          position: relative;
          width: 285px;
          height: 40px;
          display: flex;
          align-items: center;         

          div {
            display: inline;
          }

          > .editIcon {
            padding: 10px;
            width: 20px;
            height: 20px;
            color: #226666;
          }

          > .nameNoEdit {
            display: flex;
            align-items: center;
            width: 273px;
            margin-left: 6px;
            margin-right: 6px;
            border: 0px;
            background-color: #232732;
            height: 40px;

            &:hover {
              background-color: #273945;
              cursor: text;
            }

            >.nameArea {
              padding: 10px;
              max-width: 273px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }

          > .nameEdit {
            display: flex;
            width: 273px;
            margin-left: 6px;
            margin-right: 6px;
            height: 40px;
            color: #eeeeee;
          }
        }

        .cardContent {
          position: relative;
          padding-top: 8px;
          display: flex;

          .inofTable {
            display: flex;
            width: 285px;
            height: fit-content;
            flex-flow: column wrap;
            margin-bottom: 20px;
            
            .infoRow {
              display: flex;
              flex-flow: row wrap;
              justify-content: space-between;
              width: 285px;
              height: 20px;
              .rowTitle {
                display: flex;
                width: 86px;
                align-items: center;
                color: #999999;
                font-size: 12px;
                line-height: 14px;
                font-family: Roboto;
                padding-left: 16px;
              }
              .rowValue {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: right;
                width: 167px;
                color: #CCCCCC;
                font-size: 12px;
                line-height: 14px;
                font-family: Roboto;
                padding-right: 16px;
              }
            }
          }
        }
      }
    }
  }

  .bottom-button {
    width: 40px;
    height: 40px;
    margin-top: 20px;

    svg {
      width: 20px;
      height: 20px;
    }

    &.not-first {
      margin-left: 5px;
    }
  }

  .pagination {
    position: relative;
    margin-top: 20px;
    margin-bottom: 100px;
  }

  .noItemText {
    display: flex;
    width: 1200px;
    height: 490px;
    position: relative;
    color: #999999;
    background-color: #232732;
    font-size: 14px;
    line-height: 20px;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 100px;
    font-family: Roboto;
  }
}

.cad-data-modal {
  display: flex;
  flex-flow: column;
  .table-wrapper {
    max-height: 290px;
    height: auto;
    overflow: hidden;
    overflow-y: scroll;
    .table {
      .table-head {
        display: flex;
        background-color: #161A24;
        height: 40px;
        top: 0;
        position: sticky;
        z-index: 500;
        
        .table-row {
          display: flex;
          height: 40px;
          .table-cell {
            display: flex;
            font-family: 'Roboto', 'Noto Sans KR';
            box-sizing: border-box;
            color: #ccc;
            height: 40px;
            border-bottom: 1px solid #333333;
            font-size: 14px;
          }
        }
      }
      .table-body {
        display: flex;
        background-color: #232732;
        flex-direction: column;
        .table-row {
          display: flex;
          height: 50px;
        }
      }
      .table-cell {
        font-family: 'Roboto', 'Noto Sans KR';
        box-sizing: border-box;
        padding: 0px 10px;
        height: 50px;
        border-bottom: 1px solid #333333;
        display: flex;
        
        >div {
          display: flex;
          justify-items: center;
          align-items: center;
          color: #ccc;
        }
  
        &.no-data {
          width: 660px;
          text-align: center;
          color: #ccc;
          .modal-data {
            display: flex;
            flex-flow: row;
            align-items: center;
          }
        }
        &.checkbox {
          width: 40px;
          padding: 0px;
          align-items: center;
          justify-content: center;
          >.checkbox {
            margin: auto;
            width: 40px;
            color: #ccc;
            &.checked {
              color: #95e4b3;
            }
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
        &.project-id {
          width: 70px;
          >div {
            width: 70px;
          }
        }
        
        &.project-name {
          width: 540px;
        }
      }
    }
  }
  
  .info-wrap {
    width: 100%;
    padding: 20px 0px 0px 0px !important;
    display: flex;
    flex-flow: column;
    color: #cccccc;
    font-size: 12px;
    >.info {
      padding: 0px;
      display: flex;
      align-items: center;
    }
  }
}