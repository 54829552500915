.MainBody {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  min-width: 1200px;
  // overflow: unset;
  background-color: #232732;
  div input {
    outline: 0px;
  }

  .areaInfo {
    pointer-events: none;
    .textTest {
      border: white 1px solid;
      border-radius: 5px;
      padding: 10px;
      position: absolute;
      color: white;
      z-index: 1;
      transform: translateX(-50%) translateY(-50%);
    }
  }

  .information {
    height: 60px;
    border: solid 1px #333333;
    display: flex;
    flex-flow: row;
    align-items: center;

    .info {
      display: inline-table;
      width: 160px;
      border-right: solid 1px #333333;
      height: -webkit-fill-available;
      box-sizing: border-box;
      padding-left: 30px;

      &.shotWidth {
        width: 120px;
      }
    }

    .infoLabel {
      margin-top: 13px;
      color: #999999;
      font-size: 12px;
    }

    .inforValue {
      margin-top: 4px;
      color: #cccccc;
      font-size: 14px;
    }

    .fileLoad {
      width: 175px;
      height: 40px;
      border-radius: 6px;
      background-color: #383b45;
      margin-left: auto;
      margin-right: 30px;
      font-family: "Noto Sans KR";
      color: #ccc;
      font-size: 18px;
      display: flex;
    }
  }

  .dropFileBox {
    position: absolute;
    font-family: Noto Sans KR;
    width: 800px;
    height: 500px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #232b33;
    z-index: 1;
    display: flex;
    text-align: center;

    .content {
      margin-top: 159px;
      position: absolute;
      width: 100%;
      height: 100%;
      padding: auto;

      .button {
        width: 360px;
        height: 40px;
        margin-top: 20px;
        background: linear-gradient(to right, #66dfd9, #95e4b3);
        color: #232732;
        font-weight: bold;
        font-size: 18px;
      }

      .topText {
        font-size: 24px;
        width: 280px;
        margin: auto;
        color: #cccccc;
      }

      .bottomText {
        font-size: 18px;
        margin-top: 20px;
        color: #cccccc;
      }
      &.noEvent {
        pointer-events: none;
      }
    }

    > .file-drop {
      width: 100%;
      height: 100%;
      .file-drop-target {
        height: 100%;
      }
      .cover {
        display: none;
      }
    }
    .file-drop > .file-drop-target.file-drop-dragging-over-target {
      .cover.hidden {
        display: block;
        position: absolute;
        top: 0px;
        width: 800px;
        height: 500px;
        background: #c4c4c4;
        pointer-events: none;
        opacity: 0.3;
      }
    }

    &.hidden {
      display: none;
    }
  }

  .RenderView {
    height: calc(100vh - 122px);
  }

  .Scene {
    position: fixed;
    .toolBar {
      position: absolute;
      display: flex;
      flex-flow: row;
      z-index: 1;
      right: 0px;
      margin-top: 20px;
      margin-right: 20px;

      .rightButtons {
        display: flex;
        flex-flow: column;
        align-items: center;
        width: 49px;
      }

      .switchTabs {
        width: 49px;
        height: 58px;
        display: flex;
        flex-flow: column;
        background-color: #383b45;
        border-radius: 5px;
        margin: 0 0px 10px 0px;

        .switchButton {
          width: 45px;
          height: 26px;
          font-size: 12px;
          padding: 0px;
          margin: 2px;
          min-width: auto;
          color: #cccccc;

          &.enable {
            background-color: #eeeeee;
            color: #232732;
          }
        }
        .switchButton:hover {
          background-color: #eeeeee;
          color: #232732;
        }

        &.hidden {
          display: none;
        }
      }

      .searchDiv {
        position: relative;
        margin-right: 20px;
        .addressSearch {
          width: 360px;
          height: 40px;
          box-sizing: border-box;
          padding: 0 40px 0 10px;
          color: #999999;
          font-size: 14px;
        }
        > .icon {
          position: absolute;
          width: 20px;
          height: 20px;
          right: 0px;
          margin: 10px 10px 10px 0;
        }
      }

      .slider {
        -webkit-appearance: none;
        appearance: none;
        z-index: 1;
        width: 150px;
        background-color: #999999;
        border-radius: 6px;
        margin: 0 10px 0 0;
        height: 3px;
        transform: rotate(270deg) translateX(calc(-50% - 10px)) translateY(5px);
        outline: none;

        &.hidden {
          display: none;
        }

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 15px;
          height: 15px;
          background: #60626a;
          border-radius: 50%;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 15px;
          height: 15px;
          background: #60626a;
          border-radius: 50%;
          cursor: pointer;
        }
      }
      &.hidden {
        display: none;
      }
    }

    .Canvas {
      position: absolute;
      canvas {
        position: relative;
        outline: 0px;
      }
    }
  }

  .mainLayout {
    margin-left: auto;
    display: flex;
    flex-direction: row;
    height: 100%;
    width: fit-content;

    > .layerLayout {
      // display: flex;
      // flex-flow: column;
      width: 235px;

      > .layerList {
        height: calc(100% - 58px);
        overflow: auto;
        button {
          width: 175px;
          height: 30px;
          margin: 1px 30px;
          font-size: 12px;
          color: #eeeeee;
          background-color: #232732;
          border: solid 1px #333333;
          flex: 0 0 auto;

          &.selected {
            background-color: #eee;
            color: #232732;
          }
          &.error {
            background-color: red;
            color: #232732;
          }
        }

        button:hover {
          background-color: #eee;
          color: #232732;
        }
        button:after,
        button:before {
          background-color: #232732;
        }
      }

      > .arrowDiv {
        height: fit-content;
        display: flex;
        align-items: flex-end;
        flex: 0 0 auto;

        > .forwardArrow {
          color: #eeeeee;
          margin: 20px 30px 20px auto;
          width: 18px;
          height: 18px;
          cursor: pointer;
        }
      }
    }
    &.hidden {
      display: none;
    }
  }

  .functionalLayout {
    display: flex;
    flex-direction: column;
    width: 420px;
    flex-basis: auto;
    height: 100%;
    // overflow: auto;

    .fileNameLayout {
      flex: 0 0 auto;
      width: 100%;
      height: 114px;
      display: flex;
      flex-flow: column;
      padding-left: 30px;
      > div {
        margin-top: 20px;
        font-size: 18px;
        color: #cccccc;
        font-family: "Noto Sans KR";

        > span {
          color: #555555;
          margin-left: 10px;
          font-size: 12px;
          &.loaded {
            color: #cccccc;
          }
        }
      }

      > input {
        width: 360px;
        height: 40px;
        margin-top: 8px;
        box-sizing: border-box;
        padding: 0px 9px;
        background-color: #0c111b;
        border: 0px;
        color: #999999;
      }
    }

    .bodyLayout {
      width: 100%;
      overflow: auto;
      flex: 1;
      display: flex;
      flex-flow: column;
      padding-bottom: 20px;

      > button {
        flex: 0 0 auto;
        border: 0;
        font-size: 12px;
        width: 175px;
        height: 30px;
        border-radius: 6px;
        color: #cccccc;
        background-color: #383b45;
        margin-left: auto;
        margin-right: 30px;
        // margin-bottom: 30px;
        margin-top: 10px;
      }
      // button:hover {
      //   background-color: #60626a;
      // }
      button:after,
      button:before {
        background-color: #232732;
      }

      .addBuilding {
        width: 360px;
        height: 40px;
        background-color: #232732;
        border: solid 1px #383b45;
        border-radius: 6px;
        font-size: 18px;
        font-family: "Noto Sans KR";
      }
    }

    .switchLayout {
      height: 60px;
      width: 100%;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      border: solid 1px #333333;
      border-top: 0px;
      border-bottom: 0px;

      .switchTabs {
        height: 44px;
        width: 360px;
        display: flex;
        background-color: #383b45;
        border-radius: 5px;
        margin: auto;
        .switchButton {
          width: 177px;
          height: 40px;
          font-size: 14px;
          font-weight: bold;
          padding: 0px;
          margin: 2px;
          min-width: auto;
          color: #cccccc;

          .icon {
            margin: 0 10px;

            &.enable path {
              fill: #383b45;
            }
          }

          > span {
            justify-content: flex-start;
          }
          &.enable {
            background-color: #eeeeee;
            color: #232732;
          }
        }
      }
    }

    .saveLayout {
      width: 100%;
      height: 100px;
      flex: 0 0 auto;
      > div {
        color: #999999;
        font-size: 12px;
        width: 100%;
      }

      > button {
        border: 0;
        font-family: "Noto Sans KR";
        font-weight: bold;
        font-size: 18px;
        width: 360px;
        height: 40px;
        border-radius: 6px;
        margin-left: 30px;
        margin-top: 20px;
        background-image: linear-gradient(to right, #66dfd9, #95e4b3);

        &.sideMargin {
          margin-top: 10px;
        }

        &.navyButton {
          color: #cccccc;
          background-color: #383b45;
          background-image: none;
          font-weight: normal;
        }
      }
      > button:hover {
        background-image: linear-gradient(to left, #66dfd9, #95e4b3);
        &.navyButton {
          background-color: #60626a;
          background-image: none;
        }
      }
      > button:after,
      > button:before {
        background-image: linear-gradient(to right, #66dfd9, #95e4b3);
        &.navyButton {
          background-color: #383b45;
          background-image: none;
        }
      }

      .extraText {
        box-sizing: border-box;
        padding-left: 30px;
        margin-top: 13px;
        display: flex;

        > .end {
          margin-left: auto;
          margin-right: 30px;
        }
      }

      &.TwoButtonHeight {
        height: 150px;
      }
    }
  }
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  ::-webkit-scrollbar-button:start:decrement,
  ::-webkit-scrollbar-button:end:increment {
    display: block;
    width: 12px;
    height: 12px;
    background: rgba(0, 0, 0, 0.05);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 2.5px;
    background: #999999;
  }
}

header {
  .Head {
    width: 100%;
    height: 60px;
    background-color: #232732;
    color: #eeeeee;
    font-size: 20px;
    display: flex;
    font-family: Roboto;
    min-width: 1200px;
    .typeName {
      margin-left: 10px;
      font-size: 18px;
      font-family: "Noto Sans KR";
    }

    .headText {
      position: absolute;
      margin: auto;
      width: 100%;
      padding-top: 17px;
      text-align: center;
      min-width: 1200px;
    }

    .logo {
      position: relative;
      margin: auto 0;
      margin-left: 30px;
    }
  }
}

div {
  &.lbrBorder {
    box-sizing: border-box;
    border: solid 1px #333333;
    border-top: 0px;
  }
}

body {
  overflow: hidden;
}
