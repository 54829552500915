.inputBody {
  width: 175px;
  margin-right: 10px;

  > .name {
    font-size: 12px;
    color: #999999;
    margin-bottom: 5px;
  }

  > .box {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 40px;
    background-color: #0c111b;
    padding: 0 10px 0 5px;
    box-sizing: border-box;
  }

  .icon {
    width: 20px;
    height: 20px;
    margin-right: 5px;
    color: #eeeeee;

    &.hide {
      display: none;
    }

    &.disable {
      display: none;
    }
  }

  .value {
    margin-left: auto;
    font-size: 16px;
    padding: 0px;
    border: 0px;
    outline: none;
    overflow: auto;
    text-align: right;
    background: #0c111b;
    color: #cccccc;

    span {
      font-size: 14px;
      color: #cccccc;
    }

    &.disable {
      background-color: #232732;
    }

    &::-webkit-inner-spin-button,
    &::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .box:hover,
  .box:focus {
    border-bottom: solid 1px #999999;
  }

  .box-disable {
    display: flex;
    flex-flow: row;
    align-items: center;
    height: 40px;
    background-color: #232732;
    padding: 0 10px 0 5px;
    box-sizing: border-box;
  }
}
