.myPage {
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #1A1E28;

  .body {
    height: -webkit-fill-available;
    width: 1200px;
    margin: 0 auto;
  }
}
