.userInformation {
  position: relative;
  width: 1200px;
  min-height: calc(100vh - 332px);
  margin: 0 auto;
  overflow: -webkit-paged-x;
  background-color: #1A1E28;

  .temp-content {
    margin-top: calc((100vh - 332px - 62px - 173px) / 2);
    &.after-confirm {
      margin-top: calc((100vh - 332px - 62px - 255px) / 2);
    }
  }

  .title {
    margin-top: 32px;
    line-height: 30px;
  }

  .darkline {
    width: 100%;
    margin-top: 8px;
    height: 2px;
    background-color: #232732;
  }

  .tips {
    word-break: keep-all;
    width: 280px;
    text-align: center;
    margin: 21px auto;
  }

  .itemName{
    width: 360px;
    margin: 0 auto;

    .blank{
      width: 100%;
      height: 5px;
    }
  }

  .consent-wrap {
    margin: auto;
    width: 360px;
    display: flex;
    color: #ccc;
    margin-top: 20px;
    font-size: 12px;
    height: 30px;
    border-bottom: 1px solid #333;
    >.title {
      margin-top: 0px;
      display: flex;
      align-items: center;
    }
    >.action {
      display: flex;
      align-items: center;
      margin-left: auto;
      >.modal-btn {
        cursor: pointer;
        text-decoration: underline;
      }
      >.content {
        margin-left: 50px;
        
      }
      >.checkbox {
        margin-left: 5px;
        input {
          margin-top: 5px;
        }
        &.checked {
          color: #95e4b3;
        }
      }
    }
  }

  // .required-list {
  //   display: flex;
  //   flex-flow: row;
  //   align-items: center;
  //   margin-bottom: 5px;
  //   >.required-item {
  //     color: #999;
  //     >.icon {
  //       color: #555;
  //       width: 14px;
  //       height: 14px;
  //       margin-right: 3px;
  //     }
  //     font-size: 12px;
  //     display: flex;
  //     flex-flow: row;
  //     align-items: center;
  //     margin-right: 10px;

  //     &.true {
  //       color: #95E4B3;
  //       >.icon {
  //         color: #95E4B3;
  //       }   
  //     }
  //   }
  // }
  .icon {
    color: #555;
    &.true {
      color: #95E4B3;
    }
    &.error {
      color: #E81C1C
    }
  }

  .itemValue{
    width: 360px;
    margin: 15px auto;
  }

  .wrap-password {
    margin: -20px auto;
    width: 360px;
    height: fit-content;
    display: flex;
    flex-direction: column;
  }

  .wrap-privacy {
    margin: 38px auto;
    width: 360px;
    height: fit-content;
    display: flex;
    flex-direction: column;

    >.wrap-tos{
      margin-top: 30px;
      background-color: #161A24;
      height: fit-content;

      >.header {
        display: flex;
        height: 29px;
        border-bottom: solid;
        border-width: 1px;
        border-color: #555555;
        align-items: center;
        >.content {
          color: #eeeeee;
          margin-left: 10px;
          font-size: 14px;
        }
      }
      >.row {
        height: 29px;
        border-bottom: solid;
        border-width: 1px;
        border-color: #333333;
        display: flex;
        align-items: center;
        justify-content: space-between;
        color: #cccccc;
        font-size: 12px;
        >.title {
          margin-top: 0px;
          margin-left: 10px;
        }
        >.content {
          margin-right: 7px;
          display: flex;
          flex-direction: row;
          >.detail-link {
            text-decoration: underline;
            cursor: pointer;
          }
        }
      }
    }
  }

  .large-button {
    display: flex;
    width: 360px;
    height: 40px;
    font-size: 14px;
    font-weight: 400;
    font-family: "Noto Sans KR";
  }

  .pwDiv {
    width: fit-content;
    margin: 0 auto;
    margin-top: 29px;
    >.RegisterInput {
      .required-list {
        display: flex;
        flex-flow: row;
        align-items: center;
        margin-bottom: 5px;
        >.required-item {
          color: #666;
          >.icon {
            color: #555;
            width: 14px;
            height: 14px;
            margin-right: 3px;
          }
          font-size: 12px;
          display: flex;
          flex-flow: row;
          align-items: center;
          margin-right: 10px;

          &.true {
            color: #95E4B3;
            >.icon {
              color: #95E4B3;
            }   
          }
        }
      }
      .icon {
        color: #555;
        &.true {
          color: #95E4B3;
        }
        &.error {
          color: #E81C1C
        }
      }
    }

    > .inputValue {
      color: #cccccc;
      width: 360px;

      .icon{
        color: #555;
        width: 14px;
        height: 14px;
        margin-right: 12.5px;
      }
    }
  }
  
  .buttonGroup {
    display: flex;
    width: fit-content;
    margin: 0 auto;
    margin-top: 10px;

    > .cancel {
      width: 175px;
      margin-right: 10px;
      font-size: 14px;
      font-weight: 400;
      font-family: "Noto Sans KR";
    }

    > .revise {
      width: 360px;
      font-size: 14px;
      font-weight: 400;
      font-family: "Noto Sans KR";
    }

    > .enter {
      width: 360px;
    }
  }
}
