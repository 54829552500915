.ProjectFormJiguInfo {
  height: calc(100vh - 60px);
  width: 356px;
  background-color: #1a1e28;
  box-sizing: border-box;
  >.content {
    >.header {
      height: 70px;
      display: flex;
      padding-left: 30px;
      align-items: center;
      color: #ccc;
      font-size: 18px;
      border-bottom: 1px solid #333;
      box-sizing: border-box;
      >.close-btn {
        >.icon {
          
        }
      }
    }
    >.info-wrap {
      display: flex;
      flex-flow: row;
      >.info-nav {
        width: 100px;
        box-sizing: border-box;
        height: calc(100vh - 130px);
        overflow-y: scroll;
        text-align: center;
        border-right: 1px solid #333;
        background-color: #1a1e28;
        >.area-content {
          >.header {
            width: 100%;
            box-sizing: border-box;
            color: #ccc;
            font-size: 14px;
            height: 24px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #ccc;
          }
          >.jibun {
            width: 100%;
            box-sizing: border-box;
            cursor: pointer;
            height: 20px;
            color: #999;
            font-size: 12px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-bottom: 1px solid #333;
            &:hover {
              color: #95e4b3;
            }
            &.active {
              color: #95e4b3;
              border-right: 1px solid inherit;
            }
          }
        }
      }
      >.info {
        width: 256px;
        box-sizing: border-box;
        padding: 10px 20px 20px 20px;
        overflow-y: scroll;
        height: calc(100vh - 130px);
        .btn {
          .icon {
            color: #48ddf2;
          }
        }
        >.address {
          padding-bottom: 20px;
          border-bottom: 1px solid #555;
          box-sizing: border-box;
          >.title {
            font-size: 12px;
            color: #999;
            margin-bottom: 5px;
          }
          >.content {
            font-size: 14px;
            color: #ccc;
          }
          
        }
        >.jigu-raw {
          margin-top: 20px;
          display: flex;
          flex-flow: column;
          padding-bottom: 10px;
          box-sizing: border-box;
          border-bottom: 1px solid #555;
          >.title {
            color: #48DDF2;
            font-weight: bold;
            font-size: 14px;
            height: 24px;
          }
          >.content {
            display: flex;
            flex-flow: column;
            min-height: 30px;
            padding: 5px 0px;
            font-size: 14px;
            margin-bottom: 20px;
            >.key {
              color: #999;
              font-size: 12px;
              height: 20px;
            }
            >.value {
              padding-top: 5px;
              color: #ccc;
              font-size: 14px;
              width: 100%;
            }
          }

          &.land-raw {

          }
          &.etc-raw {

          }
        }
      }
    }
  }
}