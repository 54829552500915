.ProjectFormMapRoad {
  background-color: #1A1E28;
  height: calc(100vh - 60px);
  >.header {
    height: 70px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 20px;
    border-bottom: 1px solid #333;
    box-sizing: border-box;
    >.title {
      font-size: 18px;
      color: #eee;
    }
    >.icon {
      width: 18px !important;
      height: 18px !important;
      color: #eeeeee;
      margin-left: 2px;
      cursor: pointer;
    }
    >.btn {
      margin-left: auto;
      font-size: 14px;
      height: 30px;
      padding: 0px 20px;
      width: 175px;
      .icon {
        color: #232732;
      }
    }
    .close-btn {
      margin-left: auto;
      padding: 0px;
      .close-icon {
        color: #666;
      }
    }
  }
  >.body {
    height: calc(100vh - 60px - 70px - 140px);
    box-sizing: border-box;
    overflow: auto;
    >.info-wrap {
      padding-left: 30px;
      padding-right: 30px;
      padding-top: 20px;
      >.info {
        font-size: 14px;
        color: #ccc;
      }
      >.warning {
        margin-top: 12px;
        font-size: 14px;
        color: #999;
      }
      >.btns {
        padding-top: 20px;
        display: flex;
        .btn {
          width: fit-content;
          padding: 0px 16px;
          &.road {
            margin-right: 5px;
            margin-left: auto;
          }
        }
      }
    }
    >.wrapper {
      .display-flex {
        padding-left: 30px;
      }
      .btn {
        width: 175px;
        margin-top: 20px;
        &:first-child {
          margin-right: 15px;
        }
      }
      
      >.roads-wrap {
        padding-left: 30px;
        width: 360px;
        >.header {
          height: 40px;
          width: 360px;
          color: #ccc;
          margin-top: 10px;
          display: flex;
          flex-flow: row;
          align-items: center;
          border-bottom: 1px solid #333;
          box-sizing: border-box;
          >.checkbox {
            margin-right: 5px;
          }
          >.show-btn {
            display: flex;
            flex-flow: row;
            align-items: center;
            padding: 0px;
            width: 100%;
            height: 23px;
            &:hover {
              background-color: inherit;
            }
            .title {
              font-size: 14px;
              color: #ccc;
              margin-right: auto;
            }
            .icon {
              color: #999;
              margin-left: auto;
            }
          }
        }
        >.body {
          border-bottom: 1px solid #333;
          padding-bottom: 10px;
        }
      }
    }
    >.road-loading {
      width: 360px;
      padding: 20px 0px 0px 30px;
      color: #ccc;
      .circular-progress-wrap {
        display: flex;
        align-items: center;
        justify-content: center;
        .circular-progress {
          color: #eee;
        }
      }
      
    }
  }
  >.footer {
    border-top: 1px solid #333;
    padding: 20px 30px 30px 30px;
    box-sizing: border-box;
    height: 140px;
    >.header {
      box-sizing: border-box;
      >.main-title {
        height: 20px;
        color: #eee;
        font-size: 14px;
      }
      >.sub-title {
        height: 20px;
        color: #ccc;
        font-size: 12px;
      }
    }

    >.body {
      display: flex;
      flex-flow: row;
      align-items: center;
      padding-top: 10px;
      box-sizing: border-box;
      height: 50px;
      >.select-road-project-input {
        width: 175px;
      }
      >.btn {
        width: 175px;
        margin-left: auto;
      }
    }
  }
}

.info-modal {
  width: 608px;
  height: 390px;
  display: flex;
  flex-flow: column;
  font-family: "Noto Sans KR";

  >.img {
    width: 390px;
    height: 306px;
  }

  >.description {
    display: flex;
    flex-flow: column;
    margin-top: 10px;
    color: #cccccc;
    font-size: 12px;
    >.first-title {
      color: #95E4B3;
    }

    >.second-title {
      margin-top: 9px;
    }
  }
  
}

.RoadItem {
  &:hover {
    background-color: #273945;
  }
  display: flex;
  flex-flow: row;
  align-items: center;
  padding-left: 10px;
  margin-top: 10px;
  >.checkbox {
    margin-right: 5px;
  }
  >.title {
    font-size: 14px;
    color: #ccc;
  }
  >.road-project-input {
    width: 175px;
    margin-left: auto;
  }
  >.btn {
    padding: 0px;
    min-width: 0px;
    .icon {
      color: #999;
    }
  }
}